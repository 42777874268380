import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
const Tokenizegold2 = () => {
  return (
    <div>
      <main>

        {/* <section className="mt-5 pt-5 pb-5 tokenized_gold">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb-4">
                <div className="main_title">
                  <h2 className="h2 text-primary">Exchanges & Wallets</h2>
                  <h3 className="h3 text-secondary">Exchanges and Wallets supporting ComTech Gold Token (CGO).</h3>
                </div>
              </div>
            </div>
          </div>
        </section> */}
  <section class="bg-primary-light tokenized_gold gold_pattern px-80 mt-5">
                <div class="container mt-5">
                    <div class="row">
                        <div class="col-lg-12 mb-4">
                            <div class="main_title">
                                <h2 class="h2 text-primary">Exchanges & Wallets</h2>
                                <h3 class="h3 text-secondary">Exchanges and Wallets supporting ComTech Gold Token (CGO).</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

     <section class="bg-secondary-light tokenized_gold px-80">
                <div class="pattern-layer">
                    <div class="pattern-1"></div>
                </div>
                <div class="container z-index-3">
                    <div class="row justify-content-center">
                        <div class="col-lg-12 mb-4">
                            <div class="main_title">
                                <h2 class="main-title text-center mb-4 w-100">Exchanges Supporting CGO</h2>
                                <h3 class="text-center h3 text-secondary">Trade CGO at following Exchanges.</h3>

                                <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                  el: ".swiper-pagination",
                  clickable: true
                }}
                loop={false}
                speed={2400}
                autoplay={{
                  delay: 3500
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 15
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 20
                  },

                }}
                modules={[Autoplay, Pagination, Navigation]}
              >
                <div className="swiper mySwiper mb-4 z-index-3">
                  <div className="swiper-wrapper">
                    <SwiperSlide><div className="swiper-slide">
                      <a href="https://www.lbank.com/trade/cgo_usdt" target='_blank'>
                        <div className="brand_icon">
                        <img src="assets/images/exchanges/lbank_light.png" class="iconL"/>
                                    	<img src="assets/images/exchanges/lbank.png" class="iconD"/>
                        </div></a>
                    </div>
                    </SwiperSlide> <SwiperSlide>  <div className="swiper-slide">
                      <a href="https://www.bitmart.com/trade/en-US?symbol=CGO_USDT&layout=basic&theme=dark" target='_blank'>
                        <div className="brand_icon">
                        <img src="assets/images/exchanges/bitmart.png"/>
                       
                        </div>
                      </a>
                    </div>
                    </SwiperSlide><SwiperSlide> <div className="swiper-slide">
                      <a href="https://www.bitrue.com/trade/cgo_usdt" target='_blank'>
                        <div className="brand_icon">
                        <img src="assets/images/exchanges/bitrue_light.png" class="iconL"/>
                                    	<img src="assets/images/exchanges/bitrue.png" class="iconD"/>
                        </div>
                      </a>
                    </div>
                    </SwiperSlide><SwiperSlide><div className="swiper-slide">
                      <a href="https://www.lbank.com/trade/cgo_usdt" target='_blank'>
                        <div className="brand_icon">
                        <img src="assets/images/exchanges/lbank_light.png" class="iconL"/>
                                    	<img src="assets/images/exchanges/lbank.png" class="iconD"/>
                        </div></a>
                    </div>
                    </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                      <a href="https://www.bitmart.com/trade/en-US?symbol=CGO_USDT&layout=basic&theme=dark" target='_blank'>
                        <div className="brand_icon">
                          <img src="assets/images/bitmart.png" />
                        </div>
                      </a>
                    </div>
                    </SwiperSlide><SwiperSlide> <div className="swiper-slide">
                      <a href="https://www.bitrue.com/trade/cgo_usdt" target='_blank'>
                        <div className="brand_icon">
                        <img src="assets/images/exchanges/bitrue_light.png" class="iconL"/>
                                    	<img src="assets/images/exchanges/bitrue.png" class="iconD"/>
                        </div>
                      </a>
                    </div>
                    </SwiperSlide></div>
                </div> <br />
              </Swiper>
                            </div>
                        </div>
                    </div>
                  
                </div>

               
            </section>

        {/* <section className="pt-5 pb-5 tokenized_gold bg-primary-dark">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9 mb-4">
                <div className="main_title">
                  <h2 className="h2 text-primary">Exchanges Supporting CGO</h2>
                  <h3 className="h3 text-secondary">Trade CGO at following Exchanges.</h3>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">

              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                  el: ".swiper-pagination",
                  clickable: true
                }}
                loop={false}
                speed={2400}
                autoplay={{
                  delay: 3500
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 15
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 20
                  },

                }}
                modules={[Autoplay, Pagination, Navigation]}
              >
                <div className="swiper mySwiper mb-4 z-index-3">
                  <div className="swiper-wrapper">
                    <SwiperSlide><div className="swiper-slide">
                      <a href="https://www.lbank.com/trade/cgo_usdt" target='_blank'>
                        <div className="brand_icon">
                          <img src="assets/images/lbank.png" />
                        </div></a>
                    </div>
                    </SwiperSlide> <SwiperSlide>  <div className="swiper-slide">
                      <a href="https://www.bitmart.com/trade/en-US?symbol=CGO_USDT&layout=basic&theme=dark" target='_blank'>
                        <div className="brand_icon">
                          <img src="assets/images/bitmart.png" />
                        </div>
                      </a>
                    </div>
                    </SwiperSlide><SwiperSlide> <div className="swiper-slide">
                      <a href="https://www.bitrue.com/trade/cgo_usdt" target='_blank'>
                        <div className="brand_icon">
                          <img src="assets/images/bitrue.png" />
                        </div>
                      </a>
                    </div>
                    </SwiperSlide><SwiperSlide><div className="swiper-slide">
                      <a href="https://www.lbank.com/trade/cgo_usdt" target='_blank'>
                        <div className="brand_icon">
                          <img src="assets/images/lbank.png" />
                        </div></a>
                    </div>
                    </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                      <a href="https://www.bitmart.com/trade/en-US?symbol=CGO_USDT&layout=basic&theme=dark" target='_blank'>
                        <div className="brand_icon">
                          <img src="assets/images/bitmart.png" />
                        </div>
                      </a>
                    </div>
                    </SwiperSlide><SwiperSlide> <div className="swiper-slide">
                      <a href="https://www.bitrue.com/trade/cgo_usdt" target='_blank'>
                        <div className="brand_icon">
                          <img src="assets/images/bitrue.png" />
                        </div>
                      </a>
                    </div>
                    </SwiperSlide></div>
                </div> <br />
              </Swiper>

            </div>
          </div>

        </section> */}

        {/* <section className="pt-5 pb-5 tokenized_gold">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9 mt-5 mb-4">
                <div className="main_title">
                  <h2 className="h2 text-primary">Supported Wallets</h2>
                  <h3 className="h3 text-secondary">ComTech Gold (CGO) is supported by following Wallets.</h3>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-6 mb-4 g-2">
                <div className="supply_box h-100 page_2">
                  <div className="supply_icon text-center mb-2">
                    <img src="assets/images/dcent-wallet-wa.png" alt="pdf" />
                  </div>
                  <div className="supply_content text-center">
                    <h5 className="mb-3">D'CENT Wallet</h5>
                    <p>Developed by IoTrust, this Korean wallet offers the highest security standards for Biometric, Card, and Software Wallets. Available on iOS and Android.</p>
                  </div>
                  <div className="supply_button text-center">
                    <Link to="https://apps.apple.com/kr/app/dcent-wallet/id1447206611" target='_blank' className="btn btn-outline-primary">IOS Wallet</Link>
                    <Link to="https://play.google.com/store/apps/details?id=com.kr.iotrust.dcent.wallet&utm_source=dcentwallet&utm_campaign=mobileapp" target='_blank' className="btn btn-outline-primary">Android Wallet</Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-6 mb-4">
                <div className="supply_box h-100 page_2">
                  <div className="supply_icon text-center mb-2">
                    <img src="assets/images/guarda-wallet.png" alt="pdf" />
                  </div>
                  <div className="supply_content text-center">
                    <h5 className="mb-3">Guarda Wallet</h5>
                    <p>Launched in 2017, Guarda Wallet is a secure non-custodial wallet for all of the major cryptocurrencies. Available on Windows, Mac, Linux, Chrome, iOS, and Android.</p>
                  </div>
                  <div className="supply_button text-center">
                    <Link to="https://guarda.com/web-wallet" target='_blank' className="btn btn-outline-primary">Web Wallet</Link>
                    <Link to="https://guarda.com/desktop" target='_blank' className="btn btn-outline-primary">Desktop Wallet</Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-6 mb-4">
                <div className="supply_box h-100 page_2">
                  <div className="supply_icon text-center mb-2">
                    <img src="assets/images/ellipal-wallet.png" alt="pdf" />
                  </div>
                  <div className="supply_content text-center">
                    <h5 className="mb-3">Ellipal Wallet</h5>
                    <p>Elipal is a one-stop crypto wallet app. It holds multiple types of cryptocurrency and connects to crypto exchanges. Available on Android and as a hardware wallet.</p>
                  </div>
                  <div className="supply_button text-center">
                    <Link to="https://www.ellipal.com/pages/coldwallet" target='_blank' className="btn btn-outline-primary">Hardware Wallet</Link>
                    <Link to="https://www.ellipal.com/pages/ellipal-wallet-app" target='_blank' className="btn btn-outline-primary">Mobile Wallet</Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-6 mb-4">
                <div className="supply_box h-100 page_2">
                  <div className="supply_icon text-center mb-2">
                    <img src="assets/images/trezor-wallet.png" alt="pdf" />
                  </div>
                  <div className="supply_content text-center">
                    <h5 className="mb-3">Trezor Wallet</h5>
                    <p>Trezor is designed to be a highly secure hardware-only wallet. Unlike app or desktop based wallets, Trezor cannot simply be hacked.</p>
                  </div>
                  <div className="supply_button text-center">
                    <Link to="https://trezor.io" target='_blank' className="btn btn-outline-primary">Hardware Wallet</Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-6 mb-4">
                <div className="supply_box h-100 page_2">
                  <div className="supply_icon text-center mb-2">
                    <img src="assets/images/infinity-wallet.png" alt="pdf" />
                  </div>
                  <div className="supply_content text-center">
                    <h5 className="mb-3">Infinity Wallet</h5>
                    <p>The ultimate gateway to decentralized finance in a user-friendly, multi-currency and convenient cryptocurrency wallet for all your needs.</p>
                  </div>
                  <div className="supply_button text-center">
                    <Link to="https://infinitywallet.io/download" target='_blank' className="btn btn-outline-primary">Web Wallet</Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-6 mb-4">
                <div className="supply_box h-100 page_2">
                  <div className="supply_icon text-center mb-2">
                    <img src="assets/images/opolo-wallet.png" alt="pdf" />
                  </div>
                  <div className="supply_content text-center">
                    <h5 className="mb-3">Opolo Wallet</h5>
                    <p>Opolo Wallet, the complete security for your crypto assets. Available on Android, Desktop and as a hardware wallet.</p>
                  </div>
                  <div className="supply_button text-center">
                    <Link to="https://www.opolo.io/download" target='_blank' className="btn btn-outline-primary">Desktop Wallet</Link>
                    <Link to="https://play.google.com/store/apps/details?id=com.opolo.io" target='_blank' className="btn btn-outline-primary">Android Wallet</Link>

                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-6 mb-4s">
                <div className="supply_box h-100 page_2">
                  <div className="supply_icon text-center mb-2">
                    <img src="assets/images/onto-wallet.png" alt="pdf" />
                  </div>
                  <div className="supply_content text-center">
                    <h5 className="mb-3">ONTO Wallet</h5>
                    <p>ONTO is a DID-based gateway for a seamless Web3 experience, allowing you to manage your digital identity, data, and assets all in one place.</p>
                  </div>
                  <div className="supply_button text-center">
                    <Link to="https://apps.apple.com/us/app/onto-cross-chain-crypto-wallet/id1436009823" target='_blank' className="btn btn-outline-primary">IOS Wallet</Link>
                    <Link to="https://play.google.com/store/apps/details?id=com.github.ontio.onto" target='_blank' className="btn btn-outline-primary">Android Wallet</Link>
                    <Link to="https://chrome.google.com/webstore/detail/onto-wallet/ifckdpamphokdglkkdomedpdegcjhjdp?" target='_blank' className="btn btn-outline-primary">Crome Extension</Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-6 mb-4s">
                <div className="supply_box h-100 page_2">
                  <div className="supply_icon text-center mb-2">
                    <img src="assets/images/mixin-wallet.png" alt="pdf" />
                  </div>
                  <div className="supply_content text-center">
                    <h5 className="mb-3">Mixin Wallet</h5>
                    <p>Mixin is a messenger that can be used to transfer all cryptocurrencies with end-to-end encryption, offering the easiest way to enter the blockchain world.</p>
                  </div>
                  <div className="supply_button text-center">
                    <Link to="https://apps.apple.com/us/app/mixin-messenger/id1322324266" target='_blank' className="btn btn-outline-primary">IOS Wallet</Link>
                    <Link to="https://play.google.com/store/apps/details?id=one.mixin.messenger" target='_blank' className="btn btn-outline-primary">Android Wallet</Link>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section> */}
             <section class="bg-primary-light tokenized_gold px-80">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-9 mb-4">
                            <div class="main_title">
                                <h2 class="h2 text-primary">Supported Wallets</h2>
                                <h3 class="h3 text-secondary">ComTech Gold (CGO) is supported by following Wallets.</h3>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-6 mb-4">
                            <div class="bg-secondary-light supply_box h-100 page_2">
                                <div class="supply_icon text-center mb-2"><img src="assets/images/wallets/dcent-wallet.png"/></div>
                                <div class="supply_content text-center">
                                    <h5 class="mb-3">D'CENT Wallet</h5>
                                    <p>Developed by IoTrust, this Korean wallet offers the highest security standards for Biometric, Card, and Software Wallets. Available on iOS and Android.</p>
                                </div>
                                <div class="supply_button text-center">
                                	<a class="btn btn-outline-primary small-padd" href="https://apps.apple.com/kr/app/dcent-wallet/id1447206611" target="_blank">IOS Wallet</a>
                                	<a class="btn btn-outline-primary small-padd" href="https://play.google.com/store/apps/details?id=com.kr.iotrust.dcent.wallet&amp;utm_source=dcentwallet&amp;utm_campaign=mobileapp" target="_blank">Android Wallet</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-6 mb-4">
                            <div class="bg-secondary-light supply_box h-100 page_2">
                                <div class="supply_icon text-center mb-2"><img src="assets/images/wallets/guarda-wallet.png"/></div>
                                <div class="supply_content text-center">
                                    <h5 class="mb-3">Guarda Wallet</h5>
                                    <p>Launched in 2017, Guarda Wallet is a secure non-custodial wallet for all of the major cryptocurrencies. Available on Windows, Mac, Linux, Chrome, iOS, and Android.</p>
                                </div>
                                <div class="supply_button text-center">
                                	<a class="btn btn-outline-primary small-padd" href="https://guarda.com/web-wallet" target="_blank">Web Wallet</a>
                                	<a class="btn btn-outline-primary small-padd" href="https://guarda.com/desktop" target="_blank">Desktop Wallet</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-6 mb-4">
                            <div class="bg-secondary-light supply_box h-100 page_2">
                                <div class="supply_icon text-center mb-2"><img src="assets/images/wallets/ellipal-wallet.png"/></div>
                                <div class="supply_content text-center">
                                    <h5 class="mb-3">Ellipal Wallet</h5>
                                    <p>Elipal is a one-stop crypto wallet app. It holds multiple types of cryptocurrency and connects to crypto exchanges. Available on Android and as a hardware wallet.</p>
                                </div>
                                <div class="supply_button text-center">
                                    <a class="btn btn-outline-primary small-padd" href="https://www.ellipal.com/pages/coldwallet" target="_blank">Hardware Wallet</a>
                                    <a class="btn btn-outline-primary small-padd" href="https://www.ellipal.com/pages/ellipal-wallet-app" target="_blank">Mobile Wallet</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-6 mb-4">
                            <div class="bg-secondary-light supply_box h-100 page_2">
                            
                                <div class="supply_icon text-center mb-2">
                                    <img src="assets/images/wallets/trezor-wallet_light.png" class="iconL"/>
                                    <img src="assets/images/wallets/trezor-wallet.png" class="iconD"/>
                                </div>
                                <div class="supply_content text-center">
                                    <h5 class="mb-3">Trezor Wallet</h5>
                                    <p>Trezor is designed to be a highly secure hardware-only wallet. Unlike app or desktop based wallets, Trezor cannot simply be hacked.</p>
                                </div>
                                <div class="supply_button text-center">
                                	<a class="btn btn-outline-primary small-padd" href="https://trezor.io" target="_blank">Hardware Wallet</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-6 mb-4">
                            <div class="bg-secondary-light supply_box h-100 page_2">
                                <div class="supply_icon text-center mb-2"><img src="assets/images/wallets/infinity-wallet.png"/></div>
                                <div class="supply_content text-center">
                                    <h5 class="mb-3">Infinity Wallet</h5>
                                    <p>The ultimate gateway to decentralized finance in a user-friendly, multi-currency and convenient cryptocurrency wallet for all your needs.</p>
                                </div>
                                <div class="supply_button text-center">
                                	<a class="btn btn-outline-primary small-padd" href="https://infinitywallet.io/download" target="_blank">Web Wallet</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-6 mb-4">
                            <div class="bg-secondary-light supply_box h-100 page_2">
                                <div class="supply_icon text-center mb-2"><img src="assets/images/wallets/opolo-wallet.png"/></div>
                                <div class="supply_content text-center">
                                    <h5 class="mb-3">Opolo Wallet</h5>
                                    <p>Opolo Wallet, the complete security for your crypto assets. Available on Android, Desktop and as a hardware wallet.</p>
                                </div>
                                <div class="supply_button text-center">
                                	<a class="btn btn-outline-primary small-padd" href="https://www.opolo.io/download" target="_blank">Desktop Wallet</a>
                                	<a class="btn btn-outline-primary small-padd" href="https://play.google.com/store/apps/details?id=com.opolo.io" target="_blank">Android Wallet</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-6 mb-4s">
                            <div class="bg-secondary-light supply_box h-100 page_2">
                                <div class="supply_icon text-center mb-2">
                                    <img src="assets/images/wallets/onto-wallet_light.png" class="iconL"/>
                                    <img src="assets/images/wallets/onto-wallet.png" class="iconD"/>
                                </div>
                                <div class="supply_content text-center">
                                    <h5 class="mb-3">ONTO Wallet</h5>
                                    <p>ONTO is a DID-based gateway for a seamless Web3 experience, allowing you to manage your digital identity, data, and assets all in one place.</p>
                                </div>
                                <div class="supply_button text-center">
                                	<a class="btn btn-outline-primary small-padd" href="https://apps.apple.com/us/app/onto-cross-chain-crypto-wallet/id1436009823" target="_blank">IOS Wallet</a>
                                    <a class="btn btn-outline-primary small-padd" href="https://play.google.com/store/apps/details?id=com.github.ontio.onto" target="_blank">Android Wallet</a>
                                	<a class="btn btn-outline-primary small-padd" href="https://chrome.google.com/webstore/detail/onto-wallet/ifckdpamphokdglkkdomedpdegcjhjdp?" target="_blank">Crome Extension</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-6 mb-4s">
                            <div class="bg-secondary-light supply_box h-100 page_2">
                                <div class="supply_icon text-center mb-2"><img src="assets/images/wallets/mixin-wallet.png"/></div>
                                <div class="supply_content text-center">
                                    <h5 class="mb-3">Mixin Wallet</h5>
                                    <p>Mixin is a messenger that can be used to transfer all cryptocurrencies with end-to-end encryption, offering the easiest way to enter the blockchain world.</p>
                                </div>
                                <div class="supply_button text-center">
                                	<a class="btn btn-outline-primary small-padd" href="https://apps.apple.com/us/app/mixin-messenger/id1322324266" target="_blank">IOS Wallet</a>
                                	<a class="btn btn-outline-primary small-padd" href="https://play.google.com/store/apps/details?id=one.mixin.messenger" target="_blank">Android Wallet</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
{/* 
        <section className="pt-5 pb-5 tokenized_gold bg-primary-dark">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9 mb-4">
                <div className="main_title">
                  <h2 className="h2 text-primary">Institutional Custodian</h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                  el: ".swiper-pagination",
                  clickable: true
                }}
                loop={false}
                speed={2400}
                autoplay={{
                  delay: 3500
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 15
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20
                  },

                }}
                modules={[Autoplay, Pagination, Navigation]}
              >
                <div className="swiper mySwiper mb-4 z-index-3">
                  <div className="swiper-wrapper">
                    <SwiperSlide><div className="swiper-slide">
                      <a href="https://propine.com" target='_blank'>
                        <div className="brand_icon">
                          <img src="assets/images/propine.png" />
                        </div>
                      </a>
                    </div>
                    </SwiperSlide> <SwiperSlide> <div className="swiper-slide">
                      <a href="https://www.fireblocks.com" target='_blank'>
                        <div className="brand_icon">
                          <img src="assets/images/fireblocks.png" />
                        </div>
                      </a>
                    </div>
                    </SwiperSlide>  <SwiperSlide><div className="swiper-slide">
                      <a href="https://propine.com" target='_blank'>
                        <div className="brand_icon">
                          <img src="assets/images/propine.png" />
                        </div>
                      </a>
                    </div>
                    </SwiperSlide><SwiperSlide> <div className="swiper-slide">
                      <a href="https://www.fireblocks.com" target='_blank'>
                        <div className="brand_icon">
                          <img src="assets/images/fireblocks.png" />
                        </div>
                      </a>
                    </div>
                    </SwiperSlide> </div>
                </div> <br />
              </Swiper>

            </div>
          </div>
        </section> */}

        <section class="bg-secondary-light tokenized_gold px-80 pt-0">
                <div class="pattern-layer">
                    <div class="pattern-1"></div>
                </div>
                <div class="container z-index-3">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 mt-5 mb-4">
                            <div class="main_title">
                                <h2 class="main-title1 text-center mb-4 w-100">Institutional Custodian</h2>
                               

                                <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                  el: ".swiper-pagination",
                  clickable: true
                }}
                loop={false}
                speed={2400}
                autoplay={{
                  delay: 3500
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 15
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20
                  },

                }}
                modules={[Autoplay, Pagination, Navigation]}
              >
                <div className="swiper mySwiper mb-4 z-index-3">
                  <div className="swiper-wrapper">
                    <SwiperSlide><div className="swiper-slide">
                      <a href="https://propine.com" target='_blank'>
                        <div className="brand_icon">
                        <img src="assets/images/institutional-custodian/propine.png" class="iconD"/>
                                <img src="assets/images/institutional-custodian/propine_light.png" class="iconL"/>
                        </div>
                      </a>
                    </div>
                    </SwiperSlide> <SwiperSlide> <div className="swiper-slide">
                      <a href="https://www.fireblocks.com" target='_blank'>
                        <div className="brand_icon">
                        <img src="assets/images/institutional-custodian/fireblocks.png" class="iconD"/>
                                <img src="assets/images/institutional-custodian/fireblocks_light.png" class="iconL"/>
                        </div>
                      </a>
                    </div>
                    </SwiperSlide>  <SwiperSlide><div className="swiper-slide">
                      <a href="https://propine.com" target='_blank'>
                        <div className="brand_icon">
                        <img src="assets/images/institutional-custodian/propine.png" class="iconD"/>
                                <img src="assets/images/institutional-custodian/propine_light.png" class="iconL"/>
                        </div>
                      </a>
                    </div>
                    </SwiperSlide><SwiperSlide> <div className="swiper-slide">
                      <a href="https://www.fireblocks.com" target='_blank'>
                        <div className="brand_icon">
                        <img src="assets/images/institutional-custodian/fireblocks.png" class="iconD"/>
                                <img src="assets/images/institutional-custodian/fireblocks_light.png" class="iconL"/>
                        </div>
                      </a>
                    </div>
                    </SwiperSlide> </div>
                </div> <br />
              </Swiper>
                            </div>
                        </div>
                    </div>
                  
                </div>

               
            </section>
            <section class="bg-primary-light tokenized_gold px-80">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-9 mb-4">
                            <div class="main_title">
                                <h2 class="h2 text-primary">Market Data and Analytics</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-lg-3 col-md-6 col-sm-6 mb-3">
                            <div class="bg-secondary-light p-4 analytics_wrap text-center border-radius h-100">
                                <div class="an_icon text-center mb-4">
                                    <img src="assets/images/market-data/coinmarketcap-logo.png" alt="coinmarketcap-logo"/>
                                </div>
                                <div class="content">
                                    <h3>CoinMarketCap</h3>
                                    <p>CoinMarketCap is the world's most-referenced price-tracking website for cryptoassets in the rapidly growing cryptocurrency space.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 mb-3">
                            <div class="bg-secondary-light p-4 analytics_wrap text-center border-radius h-100">
                                <div class="an_icon text-center mb-4">
                                    <img src="assets/images/market-data/crypto-logo.png" alt="crypto-logo"/>
                                </div>
                                <div class="content">
                                    <h3>Cryto.comt </h3>
                                    <p>Crypto.com is a world-class Singapore-based cryptocurrency exchange and digital wallet.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 mb-3">
                            <div class="bg-secondary-light p-4 analytics_wrap text-center border-radius h-100">
                                <div class="an_icon text-center mb-4">
                                    <img src="assets/images/market-data/coingecko-logo.png" alt="coingecko-logo"/>
                                </div>
                                <div class="content">
                                    <h3>CoinGecko </h3>
                                    <p>CoinGecko provides a fundamental analysis of the crypto market to track price, volume, market capitalization, etc.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 mb-3">
                            <div class="bg-secondary-light p-4 analytics_wrap text-center border-radius h-100">
                                <div class="an_icon text-center mb-4">
                                    <img src="assets/images/market-data/binance-logo.png" alt="binance-logo"/>
                                </div>
                                <div class="content">
                                    <h3>Binance</h3>
                                    <p>Binance is a cryptocurrency exchange which is the largest exchange in the world in terms of daily trading volume of cryptocurrencies.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-3 analysis_data">
                            <h6>Disclaimer for Links to External, Third-Party WebSite</h6>
                            <p>Links to external or third-party Web sites are provided solely for visitors' convenience. When you access an external Web site, keep in mind that comtechgold.com has no control over its content. Links taken to other sites are done at your own risk, and comtechgold.com accepts no liability for any linked sites or their content.</p>
                            <p>Any link from comtechgold.com to an external Web site does not imply or mean that comtechgold.com endorses or accepts any responsibility for the content or the use of such Web site. comtechgold.com does not give any representation regarding the quality, safety, suitability, or reliability of any external Web sites or any of the content or materials contained in them. It is essential for users to take necessary precautions, especially to ensure appropriate safety from viruses, worms, Trojan horses, and other potentially destructive items.</p>
                            <p>When visiting external Web sites, users should review those Websites' privacy policies and other terms of use to learn more about what, why, and how they collect and use personally identifiable information.</p>
                        </div>
                    </div>
                </div>
            </section>
        {/* <section className="pt-5 pb-5 tokenized_gold">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9 mb-4">
                <div className="main_title">
                  <h2 className="h2 text-primary">Market Data and Analytics</h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-6 col-6 mb-4">
                <div className="analytics_wrap text-center">
                  <div className="an_icon text-center mb-4">
                    <Link to="https://coinmarketcap.com/currencies/comtech-gold" target="_blank">  <img src="assets/images/coinmarketcap-logo.png" alt="coinmarketcap-logo" /></Link>
                  </div>
                  <div className="content">
                    <Link to="https://coinmarketcap.com/currencies/comtech-gold" target="_blank" style={{ textDecoration: "none" }}> <h3>CoinMarketCap</h3></Link>
                    <p>CoinMarketCap is the world's most-referenced price-tracking website for cryptoassets in the rapidly growing cryptocurrency space.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-6 mb-4">
                <div className="analytics_wrap text-center">
                  <div className="an_icon text-center mb-4">
                    <Link to="https://crypto.com/price/comtech-gold" target="_blank">  <img src="assets/images/crypto-logo.png" alt="crypto-logo" /></Link>
                  </div>
                  <div className="content">
                    <Link to="https://crypto.com/price/comtech-gold" target="_blank" style={{ textDecoration: "none" }}>  <h3>Crypto.com </h3></Link>
                    <p>Crypto.com is a world-class Singapore-based cryptocurrency exchange and digital wallet.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-6 mb-4">
                <div className="analytics_wrap text-center">
                  <div className="an_icon text-center mb-4">
                    <Link to="https://www.coingecko.com/en/coins/comtech-gold" target="_blank">   <img src="assets/images/coingecko-logo.png" alt="coingecko-logo" /></Link>
                  </div>
                  <div className="content">
                    <Link to="https://www.coingecko.com/en/coins/comtech-gold" target="_blank" style={{ textDecoration: "none" }}>   <h3>CoinGecko </h3></Link>
                    <p>CoinGecko provides a fundamental analysis of the crypto market to track price, volume, market capitalization, etc.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-6 mb-4">
                <div className="analytics_wrap text-center">
                  <div className="an_icon text-center mb-4">
                    <Link to="https://www.binance.com/en/price/comtech-gold" target="_blank">   <img src="assets/images/binance-logo.png" alt="binance-logo" /></Link>
                  </div>
                  <div className="content">
                    <Link to="https://www.binance.com/en/price/comtech-gold" target="_blank" style={{ textDecoration: "none" }}>   <h3>Binance</h3></Link>
                    <p>Binance is a cryptocurrency exchange which is the largest exchange in the world in terms of daily trading volume of cryptocurrencies.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-5 analysis_data">
                <h6>Disclaimer for Links to External, Third-Party Web Sites</h6>
                <p>Links to external or third-party Web sites are provided solely for visitors' convenience. When you access an external Web site, keep in mind that comtechgold.com has no control over its content. Links taken to other sites are done at your own risk, and comtechgold.com accepts no liability for any linked sites or their content.</p>
                <p>Any link from comtechgold.com to an external Web site does not imply or mean that comtechgold.com endorses or accepts any responsibility for the content or the use of such Web site. comtechgold.com does not give any representation regarding the quality, safety, suitability, or reliability of any external Web sites or any of the content or materials contained in them. It is essential for users to take necessary precautions, especially to ensure appropriate safety from viruses, worms, Trojan horses, and other potentially destructive items.</p>
                <p>When visiting external Web sites, users should review those Websites' privacy policies and other terms of use to learn more about what, why, and how they collect and use personally identifiable information.</p>
              </div>
            </div>
          </div>
        </section> */}

      </main>
    </div>
  )
}

export default Tokenizegold2