import React, { useState } from 'react';
import axios
from 'axios';
const Contactus = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    mobile: '',
    message: '',
    country: 'select',
  });

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    mobile: '',
    message: '',
    country: '',
  });

const mobileRegex = /^[0-9]+$/;
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const textRegex = /^[A-Za-z]+$/;

const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });
  setErrors({ ...errors, [name]: '' });

  if ((name === 'firstName' || name === 'lastName') && !textRegex.test(value)) {
    setErrors({ ...errors, [name]: 'Please enter a valid name (letters only)' });
  }

  if (name === 'mobile' && !mobileRegex.test(value)) {
    setErrors({ ...errors, [name]: 'Please enter a valid mobile number' });
  }

  if (name === 'email' && !emailRegex.test(value)) {
    setErrors({ ...errors, [name]: 'Please enter a valid email address' });
  }
};

const handleSubmit = async (e) => {
  e.preventDefault();

  const validationErrors = {};
  let hasErrors = false;

  for (const field in formData) {
    if (formData[field] === '' || formData[field] === 'select') {
      validationErrors[field] = 'This field is required';
      hasErrors = true;
    }
  }

  if (!textRegex.test(formData.firstName)) {
    validationErrors.firstName = 'Please enter a valid first name (letters only)';
    hasErrors = true;
  }

  if (!textRegex.test(formData.lastName)) {
    validationErrors.lastName = 'Please enter a valid last name (letters only)';
    hasErrors = true;
  }

  if (!mobileRegex.test(formData.mobile)) {
    validationErrors.mobile = 'Please enter a valid mobile number';
    hasErrors = true;
  }

  if (!emailRegex.test(formData.email)) {
    validationErrors.email = 'Please enter a valid email address';
    hasErrors = true;
  }

    if (hasErrors) {
     
      setErrors(validationErrors);
    } else {
      try {
        
        const response = await axios.post('https://appapi.comtechgold.com/api/contact/create', formData);
        //window.alert('Form submitted successfully. Thanks for writing to us, our team will contact you soon');
      
        if (response.status === 200) {
          window.alert('Thanks for writing to us, our team will contact you soon');
        } else {
          window.alert('Form submission failed');
        }
       
        setFormData({
          firstName: '',
          lastName: '',
          companyName: '',
          email: '',
          mobile: '',
          message: '',
          country: 'select',
        });
      } catch (error) {
      
        window.alert('Form submission failed');
      }
    }
  };
  return (
    <div>
      <section className="pt-5 pb-5 mt-5 bg-primary-contactus">
        <div className="container pt-4 mt-5 mb-5">
          <div className="row">
            <div className="col-lg-12 mb-0">
              <div className="main_title">
                <h5 className="h5 text-secondary mb-0">GET IN TOUCH</h5>
                <h2 className="h2 text-primary">Contact Us</h2>
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <div className="contact_left_box bg-secondary-light">
                <h5>Ready to discover more about ComTech Gold?</h5>
                <p>Please click Contact Us button below and fill up the form.</p>
                <div className="mt-5">
                  <a
                    className="btn btn-secondary"
                    data-bs-toggle="modal"
                    data-bs-target="#team1"
                  >
                    Contact Us Now
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <div className="contact_left_box bg-secondary-light">
                <div className="d-flex flex-wrap get_in_touch">
                  <div className="add_icon">
                    <img src="assets/images/address_icon.png" alt="Address Icon" />
                  </div>
                  <div className="contact_text">
                    <h4 className="h4 mb-0">Address</h4>
                    <p>
                    15th Floor, 1509, Platinum Tower, Cluster I, Next to Almas Tower, Jumeirah Lake Towers, DMCC, Dubai, U.A.E
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-wrap get_in_touch">
                  <div className="add_icon">
                    <img src="assets/images/main_icon.png" alt="Mail Icon" />
                  </div>
                  <div className="contact_text">
                    <h4 className="h4 mb-0">Mail</h4>
                    <p className="h5">
                      <a href="mailto: info@comtechgold.com" style={{color:"rgb(6 75 56 / 60%)"}}>info@comtechgold.com</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal fade"
        id="team1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="team1Label"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
          <div className="modal-content bg_primary_gradiant">
            <button
              type="button"
              className="btn-close btn-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-body">
              <div className="team_detail p-4">
                <div className="sub_name">
                  <span className="fs-22 text-gradiant">GET IN TOUCH</span>{' '}
                </div>
                <h2 className="fs-38 mb-0 text-white">Contact Us</h2>

                <div className="col-md-12 mt-4">
                  <div className="contact_form contact_left_box p-4">
                    <h5 className="text-white">
                      Ready to discover more about ComTech Gold?
                    </h5>

                    <form className="mt-3" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12 mb-4">
                          <input
                            type="text"
                            className={`form-control ${
                              errors.firstName ? 'is-invalid' : ''
                            }`}
                            placeholder="First Name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            autoComplete="off"
                            
                          />
                          {errors.firstName && (
                            <div className="invalid-feedback">
                              {errors.firstName}
                            </div>
                          )}
                        </div>
                        <div className="col-12 mb-4">
                          <input
                            type="text"
                            className={`form-control ${
                              errors.lastName ? 'is-invalid' : ''
                            }`}
                            placeholder="Last Name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            autoComplete="off"
                            
                          />
                          {errors.lastName && (
                            <div className="invalid-feedback">
                              {errors.lastName}
                            </div>
                          )}
                        </div>

                        <div className="col-12 mb-4">
                          <input
                            type="text"
                            className={`form-control ${
                              errors.companyName ? 'is-invalid' : ''
                            }`}
                            name="companyName"
                            placeholder="Company Name"
                            value={formData.companyName}
                            onChange={handleChange}
                            autoComplete="off"
                            
                          />
                          {errors.companyName && (
                            <div className="invalid-feedback">
                              {errors.companyName}
                            </div>
                          )}
                        </div>
                        <div className="col-12 mb-4">
                          <input
                            type="text"
                            className={`form-control ${
                              errors.email ? 'is-invalid' : ''
                            }`}
                            name="email"
                            placeholder="Email ID"
                            value={formData.email}
                            onChange={handleChange}
                            autoComplete="off"
                            
                          />
                          {errors.email && (
                            <div className="invalid-feedback">{errors.email}</div>
                          )}
                        </div>
                        <div className="col-12 mb-4">
                          <input
                            type="text"
                            className={`form-control ${
                              errors.mobile ? 'is-invalid' : ''
                            }`}
                            name="mobile"
                            placeholder="Mobile No"
                            value={formData.mobile}
                            onChange={handleChange}
                            autoComplete="off"
                            
                          />
                          {errors.mobile && (
                            <div className="invalid-feedback">{errors.mobile}</div>
                          )}
                        </div>
                        <div className="col-12 mb-4">
                          <input
                            type="text"
                            className={`form-control ${
                              errors.message ? 'is-invalid' : ''
                            }`}
                            name="message"
                            placeholder="Message"
                            value={formData.message}
                            onChange={handleChange}
                            autoComplete="off"
                            
                          />
                          {errors.message && (
                            <div className="invalid-feedback">{errors.message}</div>
                          )}
                        </div>
                        <div className="col-lg-6 mb-4">
                          <select
                            className={`form-select ${
                              errors.country ? 'is-invalid' : ''
                            }`}
                            id="country"
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                            
                          >
                           <option value="select">Select Country</option>
                           <option value="Afghanistan">Afghanistan</option>
                <option value="Åland Islands">Åland Islands</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antarctica">Antarctica</option>
                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                <option value="Botswana">Botswana</option>
                <option value="Bouvet Island">Bouvet Island</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                <option value="Brunei Darussalam">Brunei Darussalam</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">Central African Republic</option>
                <option value="Chad">Chad</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote D'ivoire">Cote D'ivoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Territories">French Southern Territories</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guernsey">Guernsey</option>
                <option value="Guinea">Guinea</option>
                <option value="Guinea-bissau">Guinea-bissau</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="India">India</option>
                <option value="Indonesia">Indonesia</option>
                <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jersey">Jersey</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                <option value="Korea, Republic of">Korea, Republic of</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macao">Macao</option>
                <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malawi">Malawi</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                <option value="Moldova, Republic of">Moldova, Republic of</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montenegro">Montenegro</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Namibia">Namibia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherlands">Netherlands</option>
                <option value="Netherlands Antilles">Netherlands Antilles</option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau">Palau</option>
                <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Philippines">Philippines</option>
                <option value="Pitcairn">Pitcairn</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russian Federation">Russian Federation</option>
                <option value="Rwanda">Rwanda</option>
                <option value="Saint Helena">Saint Helena</option>
                <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                <option value="Saint Lucia">Saint Lucia</option>
                <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                <option value="Samoa">Samoa</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Serbia">Serbia</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                <option value="Taiwan">Taiwan</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                <option value="Thailand">Thailand</option>
                <option value="Timor-leste">Timor-leste</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Emirates">United Arab Emirates</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="United States">United States</option>
                <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                <option value="Uruguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Viet Nam">Viet Nam</option>
                <option value="Virgin Islands, British">Virgin Islands, British</option>
                <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                <option value="Wallis and Futuna">Wallis and Futuna</option>
                <option value="Western Sahara">Western Sahara</option>
                <option value="Yemen">Yemen</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
                          </select>
                          {errors.country && (
                            <div className="invalid-feedback">{errors.country}</div>
                          )}
                        </div>

                        <div className="col-12 mt-4">
                          <button type="submit" className="btn btn-secondary">
                            Contact Us Now
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
