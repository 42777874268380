import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const Tokenizegold = () => {
  return (
    <div className=''>
      <main>
        {/* <section className="mb-5 mt-5 pt-5 pb-5 tokenized_gold_section">
          <div className="d-flex flex-wrap p-lg-4"></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="tokenized_gold_banner pt-lg-5 pb-lg-5">
                  <h2>EXPERIENCE THE MAGIC OF TOKENIZED GOLD</h2>
                  <p>One CGO represents one pure gram of gold. Fully backed, redeemable, and regulated.</p>
                  <div className="">

                    <Link to="../assets/pdf/cgo_whitepaper_v1.pdf" download target='_blank' className="btn btn-outline-primary white_btn">White Paper</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap p-lg-4"></div>
        </section> */}
           {/* <section className="mt-5 mb-5 tokenized_gold">
          <div className="col-lg-12 mb-4">
            <div className="main_title text-center">
              <h2 className="h2 text-primary">MAKING GOLD EASY TO OWN & EASY TO TRADE</h2>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center pb-5">
              <div className="col-lg-12 tokenized_gold_box">
                <div className="bg-primary-dark gold_pattern p-lg-5 p-4 text-center border-radius tokenized_gold_box">
                  <p className="text-white fs-18 mb-0 ">Smart Contract Address : <a href="#"> xdc8f9920283470f52128bf11b0c14e798be704fd15 </a></p>
                </div>
              </div>
            </div>
          </div>
        </section> */}

   <section class="bg-primary-light mt-5 gold_pattern px-80">
                <div class="container mt-5">
                    <div class="row justify-content-center mb-5 pb-5">
                        <div class="col-lg-9">
                            <div class="z-index-3">
                                <h2 class="main-title text-center mb-4 w-100">EXPERIENCE THE MAGIC OF TOKENIZED GOLD</h2>
                                <p class="text-white fs-18 m-0 text-center">One CGO represents one pure gram of gold. Fully backed, redeemable, and regulated.</p>
                                <div class="text-center mt-4">
                                    {/* <a href="#" class="btn btn-outline-primary">White Paper</a> */}
                                    <Link to="/assets/pdf/cgo_whitepaper_v1.pdf" download target='_blank' className="btn btn-outline-primary">White Paper</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center mt-5">
                        <div class="col-lg-12">
                            <div class="vision-box bg-secondary-light z-index-3">
                                <div class="main_title">
                                    <h1 class="text-center text-primary mb-4">MAKING GOLD EASY TO OWN & EASY TO TRADE</h1>
                                </div>
                                <p class="text-white text-center link-break fs-18 mb-0">Smart Contract Address : <a href="#"> xdc8f9920283470f52128bf11b0c14e798be704fd15 </a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
     
        <section className="pt-5 pb-5 tokenized_gold tokenized_gold_box">
          <div className="container mt-5 mb-5">
            <div className="row">
              <div className="col-lg-12  mb-4">
                <div className="main_title">
                  <h2 className="h2 text-primary">Unlocking a Digital Future</h2>
                  <h3 className="h3 text-secondary">The Benefits and Advantages</h3>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 mb-4">
                <div className="feature_box flex-wrap align-items-start">
                  <div className="feature_icon">
                    <img src="assets/images/token-size-and-pricing.png" alt="" />

                  </div>
                  <div className="feature_text">
                    <h4 className="h4">Token Size and Pricing</h4>
                    <p>Each token represents 1 gram of pure gold. It will be priced at the prevailing rate for gold on international markets.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4">
                <div className="feature_box flex-wrap align-items-start">
                  <div className="feature_icon">
                    <img src="assets/images/shariah-compliant.png" alt="" />
                    {/* <img src="assets/images/token-size-and-pricing.png" alt="" /> */}
                  </div>
                  <div className="feature_text">
                    <h4 className="h4">Shariah Compliant</h4>
                    <p>Shariah Compliant Fatwa Certification awarded by Amanie Advisors Ltd, Making ComTech Gold, the first in the industry to be awarded a Shariah certification for a Gold tokenization product.</p>
                    <Link to="../assets/pdf/Fatwa_Comtech_gold_token_Executed.pdf" target='_blank' style={{ color: "#EDB246" }}>View Certificate</Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4">
                <div className="feature_box flex-wrap align-items-start">
                  <div className="feature_icon">
                    <img src="assets/images/vaulting-and-storage.png" alt="" />
                    {/* <img src="assets/images/conversion-of-token.png" alt="" /> */}
                  </div>
                  <div className="feature_text">
                    <h4 className="h4">Vaulting and Storage</h4>
                    <p>Physical gold bars are stored in the UAE in internationally accepted vaults. They are stored separately from other gold in the vault.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4">
                <div className="feature_box flex-wrap align-items-start">
                  <div className="feature_icon">
                    <img src="assets/images/gold-backing.png" alt="" />
                  </div>
                  <div className="feature_text">
                    <h4 className="h4">Gold Backing</h4>
                    <p>All gold tokens are 100% backed by real physical gold. The gold comes in standardized 1 kg bars of 999.9 purity from internationally recognized refineries.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4">
                <div className="feature_box flex-wrap align-items-start">
                  <div className="feature_icon">
                    <img src="assets/images/conversion-of-token.png" alt="" />
                    {/* <img src="assets/images/shariah-compliant.png" alt="" /> */}
                  </div>
                  <div className="feature_text">
                    <h4 className="h4">Conversion of Gold Token</h4>
                    <p>Token holders have the right to convert their tokens to physical gold. Any investor holding coins equivalent to 1 kg or more of gold can cancel their tokens in exchange for equivalent gold in multiples of 1000 tokens (1 kg gold).</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4">
                <div className="feature_box flex-wrap align-items-start">
                  <div className="feature_icon">
                    <img src="assets/images/digital-custodian.png" alt="" />
                  </div>
                  <div className="feature_text">
                    <h4 className="h4">Digital Custodian</h4>
                    <p>Gold tokens are issued with an approved digital custodian. The custodian will maintain records to offer accountability and an audit trail of all transactions in your wallet.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      


        <section class="bg-secondary-light bg-vector px-80 pt-0">
                <div class="pattern-layer">
                    <div class="pattern-1"></div>
                </div>
                <div class="container z-index-3">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 mt-5 mb-4">
                            <div class="main_title">
                                <h2 class="main-title text-center mb-4 w-100">We have the Trust of Your Trusted Ones</h2>
                            </div>
                        </div>
                    </div>
                    <Swiper
          className='swiper mySwiper mb-4 z-index-3'
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              el: ".swiper-pagination",
              clickable: true
            }}
            loop={false}
            speed={2400}
            autoplay={{
              delay: 3500,

              // autoplayDisableOnInteraction: true,
            }}

            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 15
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 30
              },
            }}
            modules={[Autoplay, Pagination, Navigation]}
          // onSlideChange={(swiper) => handleSlideChange(swiper)}
          >
            <div className="swiper mySwiper mb-4 z-index-3">
              <div className="swiper-wrapper">
                <SwiperSlide><div className="swiper-slide">
                  <div className="brand_icon">
                    {/* <img src="assets/images/partners/dcent-wallet.png" /> */}
                    <img src="assets/images/partners/dcent-wallet-light.png" class="img-fluid iconL" alt="" />
                    <img src="assets/images/partners/dcent-wallet.png" class="img-fluid iconD" alt="" />

                  </div>
                </div>
                </SwiperSlide> <SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    {/* <img src="assets/images/partners/globiance.png" /> */}
                    <img src="assets/images/partners/globiance-light.png" class="img-fluid iconL" alt="" />
                                    <img src="assets/images/partners/globiance.png" class="img-fluid iconD" alt="" />
             
                  </div>
                </div>
                </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                  <div className="brand_icon">
                    {/* <img src="assets/images/partners/xdc.png" /> */}
                    <img src="assets/images/partners/xdc-light.png" class="img-fluid iconL" alt="" />
                                    <img src="assets/images/partners/xdc.png" class="img-fluid iconD" alt="" />

                  </div>
                </div>
                </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                  <div className="brand_icon">
                    {/* <img src="assets/images/partners/amanie.png" /> */}
                    <img src="assets/images/partners/amanie-light.png" class="img-fluid iconL" alt="" />
                                    <img src="assets/images/partners/amanie.png" class="img-fluid iconD" alt="" />

                  </div>
                </div>
                </SwiperSlide>
                <SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    {/* <img src="assets/images/partners/transguard.png" /> */}
                    <img src="assets/images/partners/transguard-light.png" class="img-fluid iconL" alt="" />
                                    <img src="assets/images/partners/transguard.png" class="img-fluid iconD" alt="" />

                  </div>
                </div>
                </SwiperSlide>
                <SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <img src="assets/images/opolo.png" />
                  </div>
                </div>
                </SwiperSlide>
                <SwiperSlide>

<div className="swiper-slide">
  <div className="brand_icon">
    <img src="assets/images/bitmart.png" />
  </div>
</div>
</SwiperSlide>  
<SwiperSlide>

<div className="swiper-slide">
  <div className="brand_icon">
    {/* <img src="assets/images/partners/propine.png" /> */}
    <img src="assets/images/partners/propine-light.png" class="img-fluid iconL" alt="" />
                                    <img src="assets/images/partners/propine.png" class="img-fluid iconD" alt="" />

  </div>
</div>
</SwiperSlide>  <SwiperSlide>

<div className="swiper-slide">
  <div className="brand_icon">
    {/* <img src="assets/images/partners/Brinks.png" /> */}
    <img src="assets/images/partners/brinks-light.png" class="img-fluid iconL" alt="" />
                                    <img src="assets/images/partners/brinks.png" class="img-fluid iconD" alt="" />

  </div>
</div>
</SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                  <div className="brand_icon">
                    {/* <img src="assets/images/partners/kalyan.png" /> */}
                    <img src="assets/images/partners/kalyan-light.png" class="img-fluid iconL" alt="" />
                                    <img src="assets/images/partners/kalyan.png" class="img-fluid iconD" alt="" />

                  </div>
                </div>
                </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                  <div className="brand_icon">
                    {/* <img src="assets/images/partners/stonex.png" /> */}
                    <img src="assets/images/partners/stonex-light.png" class="img-fluid iconL" alt="" />
                                    <img src="assets/images/partners/stonex.png" class="img-fluid iconD" alt="" />

                  </div>
                </div>
                </SwiperSlide>
                <SwiperSlide>

                  <div className="swiper-slide">
                    <div className="brand_icon">
                      {/* <img src="assets/images/partners/Fab.png" /> */}
                      <img src="assets/images/partners/fab-light.png" class="img-fluid iconL" alt="" />
                                    <img src="assets/images/partners/fab.png" class="img-fluid iconD" alt="" />
                    </div>
                  </div>
                </SwiperSlide><SwiperSlide>

                  <div className="swiper-slide">
                    <div className="brand_icon">
                      {/* <img src="assets/images/partners/Zand.png" /> */}
                      <img src="assets/images/partners/zand-light.png" class="img-fluid iconL" alt="" />
                                    <img src="assets/images/partners/zand.png" class="img-fluid iconD" alt="" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>

                  <div className="swiper-slide">
                    <div className="brand_icon">
                      {/* <img src="assets/images/partners/dmcc.png" /> */}
                      <img src="assets/images/partners/dmcc-light.png" class="img-fluid iconL" alt="" />
                                    <img src="assets/images/partners/dmcc.png" class="img-fluid iconD" alt="" />

                    </div>
                  </div>
                </SwiperSlide>
           </div>
            </div> <br />
            </Swiper>
           
                </div>

               
            </section>
     
            <section class="bg-primary-light px-80">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-12">
                            <div class="bg-primary-dark border-radius gold_pattern ps-4 pe-4">
                                <div class="row align-items-center">
                                    <div class="col-lg-4 mb-3 mt-3 order-lg-2">
                                        <div class="w-100 text-center">
                                            <img src="assets/images/about_banner_img.png" alt="about_banner_img" class="mw-100" />
                                        </div>
                                    </div>
                                    <div class="col-lg-8 mb-3 mt-3 order-lg-1 pe-lg-5">
                                        <div class="z-index-3 pe-lg-5 ps-lg-4">
                                            <div class="main_title gold_color">
                                                <h5 class="fs-28 color-gold text-center text-lg-start mb-1 w-100">Built On XDC. Owned By You.</h5>
                                            </div>
                                            <p class="text-white mb-4 fs-18 text-center text-lg-start">The ComTech Gold system is built on the XDC Network.</p>
                                            <p class="mb-4 fs-18 text-center text-lg-start">XDC is the world's first Hybrid Blockchain. It connects both public state and private sub-networks. This protects your data while remaining transparent and auditable.</p>
                                            <p class="mb-4 fs-18 text-center text-lg-start">XDC's goal is to help enterprises enhance their business infrastructure, reduce costs, and improve visibility. With interoperable smart contracts, near zero fee transactions and high security, the XDC Network is designed to support a wide range of novel blockchain use cases.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="bg-primary-light px-80 tokenized_gold">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-9 mb-4">
                            <div class="main_title">
                                <h2 class="h2 text-primary">Why ComTech Gold (CGO)?</h2>
                            </div>
                        </div>
                        
                        
                        
                        <div class="col-lg-12">
                            <div class="table-responsive">
                                <table class="table table-bordered why-cgo-table">
                                    <thead>
                                        <tr>
                                            <th class="td-bg">&nbsp;</th>
                                            <th>
                                            	<img src="assets/images/logo.svg" class="img-fluid iconD img-responsive" />
                                                <img src="assets/images/logo-white.svg" class="img-fluid iconL img-responsive"/>
                                            </th>
                                            <th>PAX Gold</th>
                                            <th>Major Gold ETFs</th>
                                            <th>Retail physical gold coins, bars and other gold products</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="td-bg iq-tw-6">Custody Fees</td>
                                            <td>NO FEE</td>
                                            <td>NO FEE</td>
                                            <td>19-40 bps per annum</td>
                                            <td>Token Owner depositing the Bar</td>
                                        </tr>
                                        <tr>
                                            <td class="td-bg iq-tw-6">Minimum Purchase</td>
                                            <td>0.100 gram.</td>
                                            <td>0.01 t oz ~$20</td>
                                            <td>1 share (currently $200)</td>
                                            <td>Variable</td>
                                        </tr>
                                        <tr>
                                            <td class="td-bg iq-tw-6">Time to Settle</td>
                                            <td>INSTANT*</td>
                                            <td>15 mins to 1 hr</td>
                                            <td>INSTANT*</td>
                                            <td>T to T+5</td>
                                        </tr>
                                        <tr>
                                            <td class="td-bg iq-tw-6">Redeemable for Physical</td>
                                            <td>✔︎</td>
                                            <td>✔︎</td>
                                            <td>✗︎</td>
                                            <td>✔︎</td>
                                        </tr>
                                        <tr>
                                            <td class="td-bg iq-tw-6">Shariah Certification</td>
                                            <td>YES</td>
                                            <td>NA</td>
                                            <td>NA</td>
                                            <td>NA</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p class="text-primary mt-3 mb-0 fs-16">* Typical on-chain transactions for CGO moving on XDC network settle near instantly. When you create CGO on the XDC platform, tokens will typically be minted and delivered the same day (some larger transactions will settle the next business day). </p>
                        </div>
                        
                    </div>
                </div>
            </section>
            

        {/* <section className="pt-5 pb-5 tokenized_gold">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9 mb-4">
                <div className="main_title">
                  <h2 className="h2 text-primary">Why ComTech Gold (CGO)?</h2>
                </div>
              </div>
              <div className="col-12 mb-4">
                <div className="table-responsive bg-primary-dark bg-primary-dark-with-gold p-0">
                  <table className="table table-dark z-index-3">
                    <thead>
                      <tr>
                        <th scope="col" width="200px"></th>
                        <th className="text-center" scope="col"><img src="assets/images/logo_svg.svg" /></th>
                        <th className="text-center" scope="col">PAX Gold</th>
                        <th className="text-center" scope="col">Major Gold ETFs</th>
                        <th className="text-center" scope="col">Retail physical gold coins, bars and other gold products</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="5"> </td>
                      </tr>
                      <tr>
                        <td className="text-start ps-lg-5">Custody Fees</td>
                        <td className="text-center">NO FEE</td>
                        <td className="text-center">NO FEE</td>
                        <td className="text-center">19-40 bps per annum</td>
                        <td className="text-center">10-100 bps per annum</td>
                      </tr>
                      <tr>
                        <td colSpan="5" className="p-0"><img className="w-100" src="assets/images/dark_table_border.svg" /> </td>
                      </tr>
                      <tr>
                        <td className="text-start ps-lg-5">Minimum Purchase</td>
                        <td className="text-center">0.001 gram</td>
                        <td className="text-center">0.01 t oz ~$20</td>
                        <td className="text-center">1 share<br /> (currently $200)</td>
                        <td className="text-center">Variable</td>
                      </tr>
                      <tr>
                        <td colSpan="5" className="p-0"><img className="w-100" src="assets/images/dark_table_border.svg" /> </td>
                      </tr>
                      <tr>
                        <td className="text-start ps-lg-5">Time to Settle</td>
                        <td className="text-center">INSTANT*</td>
                        <td className="text-center">15 mins to 1 hr</td>
                        <td className="text-center">T+2 days</td>
                        <td className="text-center">T to T+5</td>
                      </tr>
                      <tr>
                        <td colSpan="5" className="p-0"><img className="w-100" src="assets/images/dark_table_border.svg" /> </td>
                      </tr>
                      <tr>
                        <td className="text-start ps-lg-5">Redeemable for Physical</td>
                        <td className="text-center">✔︎ </td>
                        <td className="text-center">✔︎ </td>
                        <td className="text-center">✗︎ </td>
                        <td className="text-center">✔︎ </td>
                      </tr>
                      <tr>
                        <td colSpan="5" className="p-0"><img className="w-100" src="assets/images/dark_table_border.svg" /> </td>
                      </tr>
                      <tr>
                        <td className="text-start ps-lg-5">Shariah Certification</td>
                        <td className="text-center">YES</td>
                        <td className="text-center">NA</td>
                        <td className="text-center">NA</td>
                        <td className="text-center">NA</td>
                      </tr>
                      <tr>
                        <td colSpan="5" className="p-0"><img className="w-100" src="assets/images/dark_table_border.svg" /> </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
                <p className="text-primary mt-3 fs-16">* Typical on-chain transactions for CGO moving on XDC network settle near instantly. When you create CGO on the XDC platform, tokens will typically be minted and delivered the same day (some larger transactions will settle the next business day). </p>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="pt-5 pb-5 tokenized_gold bg-primary-dark gold_pattern">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9 mb-4">
                <div className="main_title">
                  <h2 className="h2 text-primary">In The Media</h2>
                </div>
              </div>
            </div>

            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              pagination={{
                el: ".swiper-pagination",
                clickable: true
              }}
              loop={false}
              speed={2400}
              autoplay={{
                delay: 3500
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 15
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 30
                },
              }}
              modules={[Autoplay, Pagination, Navigation]}
            >
              <div className="swiper mySwiper mb-4 z-index-3">
                <div className="swiper-wrapper">
                  <SwiperSlide><div className="swiper-slide">
                    <div className="brand_icon">
                  <a href="https://n.yam.com/Article/20220927339130" target='_blank'>    <img src="assets/images/yamnews_light.png" /></a>
                    </div>
                  </div>
                  </SwiperSlide> <SwiperSlide> <div className="swiper-slide">
                    <div className="brand_icon">
                    <a href="https://ifnfintech.com/halal-gold-crypto-universe-expands-with-new-certified-token" target='_blank'>    <img src="assets/images/ifnfintech_light.png" /></a>
                    </div>
                  </div>
                  </SwiperSlide><SwiperSlide> <div className="swiper-slide">
                    <div className="brand_icon">
                     <a href="https://gulfnews.com/business/property/dubai-free-zone-dmcc-tokenises-gold-trade-through-comtech-deal-1.92159845" target='_blank'>  <img src="assets/images/gulfnews_light.png" /></a>
                    </div>
                  </div>
                  </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                    <div className="brand_icon">
                      <a href="https://ar.cointelegraph.com/news/gold-backed-tokens-are-making-their-home-in-the-uae" target='_blank'> <img src="assets/images/cointelegraph_light.png" /></a>
                    </div>
                  </div>
                  </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                    <div className="brand_icon">
                     <a href="https://www.tradingview.com/news/cointelegraph:59660e4fb:0" target='_blank'>  <img src="assets/images/tradingview_light.png" /></a>
                    </div>
                  </div>
                  </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                    <div className="brand_icon">
                     <a href="https://www.khaleejtimes.com/cryptocurrency/fully-gold-back-token-to-serve-islamic-investors" target='_blank'>  <img src="assets/images/khaleejtimes_light.png" /></a>
                    </div>
                  </div>
                  </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                    <div className="brand_icon">
                     <a href="https://www.nasdaq.com/press-release/the-fully-gold-backed-token-comtech-gold-cgo-is-now-available-for-trading-on-lbank" target='_blank'>  <img src="assets/images/nasdaq_light.png" /></a>
                    </div>
                  </div>
                  </SwiperSlide>
                  <SwiperSlide> <div className="swiper-slide">
                    <div className="brand_icon">
                     <a href="https://www.businesswire.com/news/home/20220926005591/en/ComTech-Gold-CGO-Becomes-the-First-100-Gold-Backed-Token-to-Receive-Shariah-Certification-in-the-MENA-Region" target='_blank'>  <img src="assets/images/businesswire_light.png" /></a>
                    </div>
                  </div>
                  </SwiperSlide>
                  <SwiperSlide> <div className="swiper-slide">
                    <div className="brand_icon">
                     <a href="https://www.livebitcoinnews.com/the-fully-gold-backed-token-comtech-gold-cgo-is-now-available-for-trading-on-lbank-exchange" target='_blank'>  <img src="assets/images/livebitcoinnews_light.png" /></a>
                    </div>
                  </div>
                  </SwiperSlide><SwiperSlide> <div className="swiper-slide">
                    <div className="brand_icon">
                     <a href="https://n.yam.com/Article/20220927339130" target='_blank'>  <img src="assets/images/yamnews_light.png" /></a>
                    </div>
                  </div>
                  </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                    <div className="brand_icon">
                     <a href="https://ifnfintech.com/halal-gold-crypto-universe-expands-with-new-certified-token" target='_blank'>  <img src="assets/images/ifnfintech_light.png" /></a>
                    </div>
                  </div>
                  </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                    <div className="brand_icon">
                     <a href="https://gulfnews.com/business/property/dubai-free-zone-dmcc-tokenises-gold-trade-through-comtech-deal-1.92159845" target='_blank'>  <img src="assets/images/gulfnews_light.png" /></a>
                    </div>
                  </div>
                  </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                    <div className="brand_icon">
                     <a href="https://ar.cointelegraph.com/news/gold-backed-tokens-are-making-their-home-in-the-uae" target='_blank'>  <img src="assets/images/cointelegraph_light.png" /></a>
                    </div>
                  </div>
                  </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                    <div className="brand_icon">
                      <a href="https://www.tradingview.com/news/cointelegraph:59660e4fb:0" target='_blank'> <img src="assets/images/tradingview_light.png" /></a>
                    </div>
                  </div>
                  </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                    <div className="brand_icon">
                     <a href="https://www.khaleejtimes.com/cryptocurrency/fully-gold-back-token-to-serve-islamic-investors" target='_blank'>  <img src="assets/images/khaleejtimes_light.png" /></a>
                    </div>
                  </div>
                  </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                    <div className="brand_icon">
                      <a href="https://www.nasdaq.com/press-release/the-fully-gold-backed-token-comtech-gold-cgo-is-now-available-for-trading-on-lbank" target='_blank'> <img src="assets/images/nasdaq_light.png" /></a>
                    </div>
                  </div>
                  </SwiperSlide> <SwiperSlide> <div className="swiper-slide">
                    <div className="brand_icon">
                     <a href="https://www.businesswire.com/news/home/20220926005591/en/ComTech-Gold-CGO-Becomes-the-First-100-Gold-Backed-Token-to-Receive-Shariah-Certification-in-the-MENA-Region" target='_blank'>  <img src="assets/images/businesswire_light.png" /></a>
                    </div>
                  </div>
                  </SwiperSlide>  <SwiperSlide> <div className="swiper-slide">
                    <div className="brand_icon">
                     <a href="https://www.livebitcoinnews.com/the-fully-gold-backed-token-comtech-gold-cgo-is-now-available-for-trading-on-lbank-exchange" target='_blank'>  <img src="assets/images/livebitcoinnews_light.png" /></a>
                    </div>
                  </div>
                  </SwiperSlide></div>
              </div> <br />
            </Swiper>

          </div>
        </section> */}




      <section class="bg-secondary-light bg-vector px-80">
        <div class="pattern-layer">
          <div class="pattern-1"></div>
        </div>
        <div class="container z-index-3">
          <div class="row justify-content-center">
            <div class="col-lg-8 mb-4">
              <div class="main_title">
                <h2 class="main-title text-center mb-4 w-100">In The Media</h2>
              </div>
            </div>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              el: ".swiper-pagination",
              clickable: true
            }}
            loop={false}
            speed={2400}
            autoplay={{
              delay: 3500
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 15
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 30
              },
            }}
            modules={[Autoplay, Pagination, Navigation]}
          >
            <div className="swiper mySwiper mb-4 z-index-3">
              <div className="swiper-wrapper">
                <SwiperSlide><div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://n.yam.com/Article/20220927339130" target='_blank'> 
                       {/* <img src="assets/images/media/yamnews.png" /> */}
                       <img src="assets/images/media/yamnews-light.png" class="img-fluid iconL" alt="" />
                                        <img src="assets/images/media/yamnews.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide> <SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://ifnfintech.com/halal-gold-crypto-universe-expands-with-new-certified-token" target='_blank'>
                          {/* <img src="assets/images/media/ifnfintech.png" /> */}
                          <img src="assets/images/media/ifnfintech-light.png" class="img-fluid iconL" alt="" />
                                        <img src="assets/images/media/ifnfintech.png" class="img-fluid iconD" alt="" />
                          </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://gulfnews.com/business/property/dubai-free-zone-dmcc-tokenises-gold-trade-through-comtech-deal-1.92159845" target='_blank'>
                        {/* <img src="assets/images/media/gulfnews.png" /> */}
                        <img src="assets/images/media/gulfnews-light.png" class="img-fluid iconL" alt="" />
                                        <img src="assets/images/media/gulfnews.png" class="img-fluid iconD" alt="" />
                        </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://cointelegraph.com/news/what-is-comtech-gold-cgo-and-how-does-it-work" target='_blank'> 
                    {/* <img src="assets/images/media/cointelegraph.png" /> */}
                    <img src="assets/images/media/cointelegraph-light.png" class="img-fluid iconL" alt="" />
                                        <img src="assets/images/media/cointelegraph.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://www.tradingview.com/news/cointelegraph:59660e4fb:0" target='_blank'>
                        {/* <img src="assets/images/media/tradingview.png" /> */}
                        <img src="assets/images/media/tradingview-light.png" class="img-fluid iconL" alt="" />
                                        <img src="assets/images/media/tradingview.png" class="img-fluid iconD" alt="" />
                        </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://www.khaleejtimes.com/cryptocurrency/fully-gold-back-token-to-serve-islamic-investors" target='_blank'> 
                     {/* <img src="assets/images/media/khaleejtimes.png" /> */}
                     <img src="assets/images/media/khaleejtimes-light.png" class="img-fluid iconL" alt="" />
                                        <img src="assets/images/media/khaleejtimes.png" class="img-fluid iconD" alt="" />
                     </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://www.nasdaq.com/press-release/the-fully-gold-backed-token-comtech-gold-cgo-is-now-available-for-trading-on-lbank" target='_blank'>
                        {/* <img src="assets/images/media/nasdaq.png" /> */}
                        <img src="assets/images/media/nasdaq-light.png" class="img-fluid iconL" alt="" />
                                        <img src="assets/images/media/nasdaq.png" class="img-fluid iconD" alt="" />
                        </a>
                  </div>
                </div>
                </SwiperSlide>
                <SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://www.businesswire.com/news/home/20220926005591/en/ComTech-Gold-CGO-Becomes-the-First-100-Gold-Backed-Token-to-Receive-Shariah-Certification-in-the-MENA-Region" target='_blank'> 
                     {/* <img src="assets/images/media/businesswire.png" /> */}
                     <img src="assets/images/media/businesswire-light.png" class="img-fluid iconL" alt="" />
                                        <img src="assets/images/media/businesswire.png" class="img-fluid iconD" alt="" />
                     </a>
                  </div>
                </div>
                </SwiperSlide>
                <SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://www.livebitcoinnews.com/the-fully-gold-backed-token-comtech-gold-cgo-is-now-available-for-trading-on-lbank-exchange" target='_blank'> 
                     {/* <img src="assets/images/media/livebitcoinnews.png" /> */}
                     <img src="assets/images/media/livebitcoinnews-light.png" class="img-fluid iconL" alt="" />
                                        <img src="assets/images/media/livebitcoinnews.png" class="img-fluid iconD" alt="" />
                     </a>
                  </div>
                </div>
                </SwiperSlide>
                <SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://www.dmcc.ae/news/dmcc-digitises-gold-trading-through-tokenisation-comtech-gold-bullion-backed-dmccs-tradeflow-platform" target='_blank'>
                        {/* <img src="assets/images/media/dmcc.png" /> */}
                        <img src="assets/images/media/dmcc-light.png" class="img-fluid iconL" alt="" />
                                        <img src="assets/images/media/dmcc.png" class="img-fluid iconD" alt="" />
                        </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://laraontheblock.com/uae-gold-tokenization-pioneers-applaud-hsbc-gold-tokenization-initiative-furthering-growth" target='_blank'>  
                    {/* <img src="assets/images/media/laraontheblock.png" /> */}
                    <img src="assets/images/media/laraontheblock-light.png" class="img-fluid iconL" alt="" />
                                        <img src="assets/images/media/laraontheblock.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://coinchapter.com/globiance-and-comtech-gold-dubai-to-offer-gold-and-silver-backed-tokens" target='_blank'> 
                     {/* <img src="assets/images/cc-logo-onlight.png" /> */}
                     <img src="assets/images/media/coinchapter-light.png" class="img-fluid iconL" alt="" />
                                        <img src="assets/images/media/coinchapter.png" class="img-fluid iconD" alt="" />
                     </a>
                  </div>
                </div>
                </SwiperSlide>
                
                <SwiperSlide><div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://n.yam.com/Article/20220927339130" target='_blank'> 
                       {/* <img src="assets/images/media/yamnews.png" /> */}
                       <img src="assets/images/media/yamnews-light.png" class="img-fluid iconL" alt="" />
                                        <img src="assets/images/media/yamnews.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide> <SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://ifnfintech.com/halal-gold-crypto-universe-expands-with-new-certified-token" target='_blank'>
                          {/* <img src="assets/images/media/ifnfintech.png" /> */}
                          <img src="assets/images/media/ifnfintech-light.png" class="img-fluid iconL" alt="" />
                                        <img src="assets/images/media/ifnfintech.png" class="img-fluid iconD" alt="" />
                          </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://gulfnews.com/business/property/dubai-free-zone-dmcc-tokenises-gold-trade-through-comtech-deal-1.92159845" target='_blank'>
                        {/* <img src="assets/images/media/gulfnews.png" /> */}
                        <img src="assets/images/media/gulfnews-light.png" class="img-fluid iconL" alt="" />
                                        <img src="assets/images/media/gulfnews.png" class="img-fluid iconD" alt="" />
                        </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://cointelegraph.com/news/what-is-comtech-gold-cgo-and-how-does-it-work" target='_blank'> 
                    {/* <img src="assets/images/media/cointelegraph.png" /> */}
                    <img src="assets/images/media/cointelegraph-light.png" class="img-fluid iconL" alt="" />
                                        <img src="assets/images/media/cointelegraph.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://www.tradingview.com/news/cointelegraph:59660e4fb:0" target='_blank'>
                        {/* <img src="assets/images/media/tradingview.png" /> */}
                        <img src="assets/images/media/tradingview-light.png" class="img-fluid iconL" alt="" />
                                        <img src="assets/images/media/tradingview.png" class="img-fluid iconD" alt="" />
                        </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://www.khaleejtimes.com/cryptocurrency/fully-gold-back-token-to-serve-islamic-investors" target='_blank'> 
                     {/* <img src="assets/images/media/khaleejtimes.png" /> */}
                     <img src="assets/images/media/khaleejtimes-light.png" class="img-fluid iconL" alt="" />
                                        <img src="assets/images/media/khaleejtimes.png" class="img-fluid iconD" alt="" />
                     </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://www.nasdaq.com/press-release/the-fully-gold-backed-token-comtech-gold-cgo-is-now-available-for-trading-on-lbank" target='_blank'>
                        {/* <img src="assets/images/media/nasdaq.png" /> */}
                        <img src="assets/images/media/nasdaq-light.png" class="img-fluid iconL" alt="" />
                                        <img src="assets/images/media/nasdaq.png" class="img-fluid iconD" alt="" />
                        </a>
                  </div>
                </div>
                </SwiperSlide>
                <SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://www.businesswire.com/news/home/20220926005591/en/ComTech-Gold-CGO-Becomes-the-First-100-Gold-Backed-Token-to-Receive-Shariah-Certification-in-the-MENA-Region" target='_blank'> 
                     {/* <img src="assets/images/media/businesswire.png" /> */}
                     <img src="assets/images/media/businesswire-light.png" class="img-fluid iconL" alt="" />
                                        <img src="assets/images/media/businesswire.png" class="img-fluid iconD" alt="" />
                     </a>
                  </div>
                </div>
                </SwiperSlide>
                <SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://www.livebitcoinnews.com/the-fully-gold-backed-token-comtech-gold-cgo-is-now-available-for-trading-on-lbank-exchange" target='_blank'> 
                     {/* <img src="assets/images/media/livebitcoinnews.png" /> */}
                     <img src="assets/images/media/livebitcoinnews-light.png" class="img-fluid iconL" alt="" />
                                        <img src="assets/images/media/livebitcoinnews.png" class="img-fluid iconD" alt="" />
                     </a>
                  </div>
                </div>
                </SwiperSlide>
                <SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://www.dmcc.ae/news/dmcc-digitises-gold-trading-through-tokenisation-comtech-gold-bullion-backed-dmccs-tradeflow-platform" target='_blank'>
                        {/* <img src="assets/images/media/dmcc.png" /> */}
                        <img src="assets/images/media/dmcc-light.png" class="img-fluid iconL" alt="" />
                                        <img src="assets/images/media/dmcc.png" class="img-fluid iconD" alt="" />
                        </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://laraontheblock.com/uae-gold-tokenization-pioneers-applaud-hsbc-gold-tokenization-initiative-furthering-growth" target='_blank'>  
                    {/* <img src="assets/images/media/laraontheblock.png" /> */}
                    <img src="assets/images/media/laraontheblock-light.png" class="img-fluid iconL" alt="" />
                                        <img src="assets/images/media/laraontheblock.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://coinchapter.com/globiance-and-comtech-gold-dubai-to-offer-gold-and-silver-backed-tokens" target='_blank'> 
                     {/* <img src="assets/images/cc-logo-onlight.png" /> */}
                     <img src="assets/images/media/coinchapter-light.png" class="img-fluid iconL" alt="" />
                                        <img src="assets/images/media/coinchapter.png" class="img-fluid iconD" alt="" />
                     </a>
                  </div>
                </div>
                </SwiperSlide>
                </div>
            </div> <br />
          </Swiper>
        </div>
      </section>



      <section class="bg-primary-light px-80">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-9 mb-4">
                            <div class="main_title">
                                <h4 class="text-center">STORE YOUR GOLD IN THE CITY OF EGOLD</h4>
                                <h2 class="h2 text-center text-primary">Dubai Is Called The City Of Gold</h2>
                                <h6 class="text-center text-secondary">Almost 1,200 tons of gold are traded in its souks. By strictly observing Shariah principles, we envision making Dubai the city of e-Gold.</h6>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-6 col-md-6 mb-4">
                            <div class="feature_box bg-secondary-light dubai_call d-flex flex-wrap align-items-start">
                                <div class="feature_icon">
                                    <img src="assets/images/shariah-compliant.png" alt="" />
                                </div>
                                <div class="feature_text">
                                    <h4 class="h4 mb-2">100% Allocated Gold</h4>
                                    <p>When you buy a CGO token, you don't buy an imaginary entitlement to unallocated gold that doesn't exist. You buy a real, physical piece of gold, identified by a unique serial number.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 mb-4">
                            <div class="feature_box bg-secondary-light dubai_call d-flex flex-wrap align-items-start">
                                <div class="feature_icon">
                                    <img src="assets/images/shariah-compliant.png" alt="" />
                                </div>
                                <div class="feature_text">
                                    <h4 class="h4 mb-2">One Token = One Fine Gram of Gold</h4>
                                    <p>And you can even invest in fractional amounts of a token, divisible out to 18 decimal points.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 mb-4">
                            <div class="feature_box bg-secondary-light dubai_call d-flex flex-wrap align-items-start">
                                <div class="feature_icon">
                                    <img src="assets/images/shariah-compliant.png" alt="" />
                                </div>
                                <div class="feature_text">
                                    <h4 class="h4 mb-2">Zero Custodian Fees</h4>
                                    <p>CGO charges Zero custodian fees. This offer is for a limited time only.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 mb-4">
                            <div class="feature_box bg-secondary-light dubai_call d-flex flex-wrap align-items-start">
                                <div class="feature_icon">
                                    <img src="assets/images/shariah-compliant.png" alt="" />
                                </div>
                                <div class="feature_text">
                                    <h4 class="h4 mb-2">Instant Settling Time</h4>
                                    <p>With ComTech Gold, you tap into the power of one of the world's largest blockchain networks. All transactions are settled at the speed of light, with a secured, decentralized verification protocol.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div class="row justify-content-center mt-5">
                        <div class="col-lg-12">
                            <div class="vision-box bg-secondary-light z-index-3">
                                <div class="row z-index-3 align-items-center">
                                    <div class="col-lg-9 mb-2 mt-2">
                                        <div class="main_title ps-lg-4">
                                            <h4 class="text-lg-start text-center text-white fs-38">White Paper</h4>
                                            <h5 class="text-lg-start text-center">Learn more about ComTech Gold (CGO) in our white paper.</h5>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 mb-2 mt-2">
                                        <div class="d-flex justify-content-center">
                                            {/* <a href="#" class="btn btn-outline-primary">Read More</a> */}
                                            <Link to="../assets/cgo_whitepaper_v1.pdf" download target='_blank' className="btn btn-outline-primary">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
            </section>
            


        {/* <section className="mt-5 pt-5 pb-5 tokenized_gold">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9 mb-4">
                <div className="main_title">
                  <h4 className="text-center">STORE YOUR GOLD IN THE CITY OF EGOLD</h4>
                  <h2 className="h2 text-primary">Dubai Is Called The City Of Gold</h2>
                  <h3 className="h3 text-secondary">Almost 1,200 tons of gold are traded in its souks. By strictly observing Shariah principles, we envision making Dubai the city of e-Gold.</h3>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 mb-4">
                <div className="bg-primary-dark dubai_call border-radius p-4 text-start h-100 tokenized_gold_box">
                  <h4>100% Allocated Gold</h4>
                  <p>When you buy a CGO token, you don't buy an imaginary entitlement to unallocated gold that doesn't exist. You buy a real, physical piece of gold, identified by a unique serial number.</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 mb-4">
                <div className="bg-primary-dark dubai_call border-radius p-4 text-start h-100 tokenized_gold_box">
                  <h4>One Token = One Fine Gram of Gold</h4>
                  <p>And you can even invest in fractional amounts of a token, divisible out to 18 decimal points.</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 mb-4">
                <div className="bg-primary-dark dubai_call border-radius p-4 text-start h-100 tokenized_gold_box">
                  <h4>Zero Custodian Fees</h4>
                  <p>CGO charges Zero custodian fees. This offer is for a limited time only.</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 mb-4">
                <div className="bg-primary-dark dubai_call border-radius p-4 text-start h-100 tokenized_gold_box">
                  <h4>Instant Settling Time</h4>
                  <p>With ComTech Gold, you tap into the power of one of the world's largest blockchain networks. All transactions are settled at the speed of light, with a secured, decentralized verification protocol.</p>
                </div>
              </div>
            </div>
          </div>
        </section> */}

{/* 
        <section className="mt-5 mb-5 pt-5 pb-5 tokenized_gold tokenized_gold_box">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="bg-primary-dark gold_pattern p-4 border-radius">
                  <div className="row z-index-3 align-items-center">
                    <div className="col-lg-9 mb-2 mt-2">
                      <div className="main_title ps-lg-4">
                        <h4 className="text-lg-start text-center text-white fs-38 fw-bold">White Paper</h4>
                        <h3 className="h3 text-lg-start text-center text-secondary">Learn more about ComTech Gold (CGO) in our white paper.</h3>
                      </div>
                    </div>
                    <div className="col-lg-3 mb-2 mt-2">
                      <div className="d-flex justify-content-center">
                        <Link to="../assets/pdf/cgo_whitepaper_v1.pdf" download target='_blank' className="btn btn-outline-primary">Read More</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

      </main>
    </div>
  )
}

export default Tokenizegold