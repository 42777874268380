import React from 'react'

const Privactpolicy = () => {
    const customStyle = {
        fontWeight: 600,
       
      };
      const bulletdotstyle = {
        fontWeight: 500,
        marginLeft:55
       
      };
      const higlight = {
        fontWeight: 500,
       
      };
  return (
    <div>
         <div class="main-contain pt-5 mt-5 mb-5" style={{textAlign:'justify'}}>
          <section>
            <div id="" class="iq-banner-2">
                <div class="banner-info">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-11">
                                <div class="banner-text iq-font-white wow fadeIn" data-wow-delay="0.1s">
                                    <h2 class="h2 text-primary">Privacy Policy</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hero-internal-separator mt-5"></div>
            </div>
          
            <section class="iq-product-description overview-block-pt pt-0">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-11">
                            <p class="fs-18">The most significant aspect of ComTech Gold is to preserve your belief and confidence in us by safeguarding your privacy. You will be provided with complete information about the process of your data being collected, stored/saved, and utilized by us. We recommend you kindly review our Privacy Policy thoroughly. You explicitly permit us to use and reveal your details as per the Privacy Policy, by visiting ComTech Gold's Website/WAP Site/Applications. You will forbid using or accessing the ComTech Gold website, WAP site, or mobile applications if you disagree with any term/terms of this policy. ComTech Gold has the authority to alter the terms of this policy without any prior communication. You are requested to keep yourself updated on any such alterations made by ComTech Gold by keeping a check regularly. The Privacy Policy must evenly pertain to the ComTech Gold desktop website, ComTech Gold mobile WAP site & ComTech Gold mobile/desktop applications.</p>
                            <p class="mt-4 mb-2 fs-22 " style={customStyle}>General</p>
                            <p class="fs-18">There will be no selling, sharing, or renting of your details to any kind of 3rd party. We also prohibit utilizing your email addresses or contact numbers for unwanted emails or/and SMS. ComTech Gold will send emails or/and SMS only about the terms of services and the Privacy Policy. ComTech Gold will disclose overall survey The most significant aspect of ComTech Gold is to preserve your belief and confidence in us by safeguarding your privacy. You will be provided with complete information about the process of your data being collected, stored/saved, and utilized by us. We recommend you kindly review our Privacy Policy thoroughly. You explicitly permit us to use and reveal your details as per the Privacy Policy, by visiting ComTech Gold's Website/WAP Site/Applications. You will forbid using or accessing the ComTech Gold website, WAP site, or mobile applications if you disagree with any term/terms of this policy. ComTech Gold has the authority to alter the terms of this policy without any prior communication. You are requested to keep yourself updated on any such alterations made by ComTech Gold by keeping a check regularly. The Privacy Policy must evenly pertain to the ComTech Gold desktop website, ComTech Gold mobile WAP site & ComTech Gold mobile/desktop applications.GeneralThere will be no selling, sharing, or renting of your details to any kind of 3rd party. We also prohibit utilizing your email addresses or contact numbers for unwanted emails or/and SMS. ComTech Gold will send emails or/and SMS only about the terms of services and the Privacy Policy. ComTech Gold will disclose overall survey details taken by 3rd party for statistical purposes. ComTech Gold is responsible to disclose your details on receiving any kind of legal order or decree stating to disclose the details.</p>
                            <p class="mt-4 mb-2 fs-22 " style={customStyle}>Personal Information</p>
                            <p class="fs-18">The personal Information suggests and comprises all the details which can be connected to a particular individual or/and to ascertain any individual for availing any service/services on ComTech Gold. When, as a user, you will browse through the ComTech Gold's site, ComTech Gold APP we shall gather information connected with the domain and the host through which you access the internet, the IP address (or Internet Protocol address) of the computer and/or the ISP (Internet Service Provider) you are utilizing, and also, the statistical data of the anonymous site.</p>
                            <p class="fs-18">Information you give us through the ComTech Gold app, when you register to use the app, sign up for an account, correspond with us, use the app chat, engage in promotions or reward schemes, or other communications:</p>
                            <p class="fs-18" style={bulletdotstyle}>•  Your name, phone number, home address, date of birth, residency,</p>
                            <p class="fs-18" style={bulletdotstyle}>•  Your email address, details of your device</p>
                            <p class="fs-18" style={bulletdotstyle}>•  Your Emirates ID information, or passport and any other identification documents</p>
                            <p class="fs-18" style={bulletdotstyle}>•  Your selfie where you take the photo of your face (face data) when you set up an account with us, and your photo if you upload one.</p>
                            <p class="fs-18" style={bulletdotstyle}>•  The log in and registration information and settings you choose for your app (including username, password)</p>
                            <p class="fs-18" style={bulletdotstyle}>•  Your location</p>
                            <p class="fs-18" style={bulletdotstyle}>•  Information you give us through the app chat or records of discussions if we contact each other</p>
                            <p class="fs-18" style={bulletdotstyle}>•  The email address you use and the contents of your email (and any attachments).</p>
                            <p class="fs-18" style={bulletdotstyle}>•  Public details from your social media profiles (Facebook, Instagram, or Twitter) if you reach out to  us via these platforms, and the contents of your messages or posts to us</p>
                            <p class="fs-18" style={bulletdotstyle}>•  Answers you give to surveys</p>
                            <p class="fs-18" style={bulletdotstyle}>•  Details about payments to and from your account, including payment reason, and beneficiaries’ details</p>
                            <p class="fs-18" style={bulletdotstyle}>•  Details about services from us and our partners that you express interest in.</p>
                            <p class="fs-18" style={bulletdotstyle}>•  Details about how you use our app.</p>


                            <p class="mt-4 mb-2 fs-22 " style={customStyle}>Information we collect from your device:</p>
                            <p class="fs-18" style={bulletdotstyle}>•  The mobile network information and operating system, the type of mobile browser you use.</p>
                            <p class="fs-18" style={bulletdotstyle}>•	Your log-in information, browser type and version and information.</p>
                            <p class="fs-18" style={bulletdotstyle}>•	The type of device you use, unique device identifier.</p>
                            <p class="fs-18" style={bulletdotstyle}>•	Your IP (Internet Protocol) address and device ID for security reasons.</p>
                            <p class="fs-18" style={bulletdotstyle}>•	Your mobile advertising ID, so we can share it with companies that help us with advertising online. You can reset this ID or limit tracking in ‘Settings’ on your phone.</p>
                            <p class="fs-18" style={bulletdotstyle}>•	Your location, including GPS and IP address, in order to verify your residential address and also to help protect you against fraud.</p>
                            <p class="fs-18" style={bulletdotstyle}>•	Information on your use of the app, including links to, through and from our website to other sites.</p>
                            <p class="fs-18" style={bulletdotstyle}>•	Transaction data around your payments and beneficiaries.</p>
                           





                            <p class="mt-4 mb-2 fs-22 " style={customStyle}>Use of Personal Information</p>
                            <p class="fs-18">We use personal information to offer you the services you explicitly solicited for, to settle disputes, fix concerns, facilitate safe services, gather money, survey customer interest in all our services, update you on offers, services, products, updates, customize your personal experience, identify & safeguard us against fraud, error, and other kinds of criminal activities, execute our terms & conditions, etc. We may periodically request you to complete some open online surveys. The surveys may request you for contact and demographic details (such as zip code, gender, age etc.). The data collected by ComTech Gold is modified and personalized as per your practice and understanding; so that you are provided and shown with a subject of your liking.</p>
                            <p class="mt-4 mb-2 fs-22 " style={customStyle}>We use details about you to:</p>
                            <p class="fs-18" style={bulletdotstyle}>•	Consider your application, including our KYC, customer due diligence and security checks.</p>
                            <p class="fs-18" style={bulletdotstyle}>•	Give you the services we agreed to in line with our terms and conditions.</p>
                            <p class="fs-18" style={bulletdotstyle}>•	To process your transactions.</p>
                            <p class="fs-18" style={bulletdotstyle}>•	Send you updates and communications about your account and other services you use if you get in touch, or we need to notify you of changes to our Services, tell you about something or contact you through customer support services.</p>
                            <p class="fs-18" style={bulletdotstyle}>•	Verify your residential address.</p>
                            <p class="fs-18" style={bulletdotstyle}>•	To handle your enquiries in our communications.</p>
                            <p class="fs-18" style={bulletdotstyle}>•	Exercise our rights under contracts we have entered into with you,</p>
                            <p class="fs-18" style={bulletdotstyle}>•	Allow our partners provide information on their services, if you agree.</p>
                            <p class="fs-18" style={bulletdotstyle}>•	Investigate and resolve complaints and other issues.</p>



                            <p class="mt-4 mb-2 fs-22 " style={customStyle}>We need to use your data to comply with the law. We use your details to:</p>
                            <p class="fs-18" style={bulletdotstyle}>•	Check your identity when you sign up or get in touch.</p>
                            <p class="fs-18" style={bulletdotstyle}>•	Check your record at immigration and fraud prevention agencies.</p>
                            <p class="fs-18" style={bulletdotstyle}>•	Prevent illegal activities like money laundering, tax evasion and fraud.</p>
                            <p class="fs-18" style={bulletdotstyle}>•	Keep records of information we hold about you in line with legal requirements.</p>
                            <p class="fs-18" style={bulletdotstyle}>•	Adhere to banking laws and regulations (these mean we sometimes need to share customer details with regulators, tax authorities, law enforcement or other third parties).</p>
                            <p class="fs-18" style={bulletdotstyle}>•	Compare information we hold about your account with your tax residency information to make sure we do not have a reason to doubt it.</p>





                            
                            <p class="mt-4 mb-2 fs-22 " style={customStyle}>Cookies</p>
                            <p class="fs-18">Cookies are a very small piece of information that gets stored by the web server running on the web browser and that information can later be referred back from that specific browser. ComTech Gold uses cookies & tracking technology based on the types of features offered. The cookies and other tracking technology will not collect any personal; but, if you earlier shared personally identifiable details, the cookies may get attached to such data. Combined cookie & tracking information may/may not be provided to third parties and more.</p>
                            <p class="mt-4 mb-2 fs-22 " style={customStyle}>Links to other websites</p>
                            <p class="fs-18">There are other websites showing links to the ComTech Gold website. When the ComTech Gold site links appear on other websites, then such websites shall gather the said personal information associated with you for identification purposes. ComTech Gold will not be liable for any such privacy rules or matters of all those linked or connected websites.</p>
                            <p class="mt-4 mb-2 fs-22 " style={customStyle}>Security</p>
                            <p class="fs-18">As far as security measures are concerned, ComTech Gold's actions to safeguard the loss, the misuse and the alterations made to any information, are under sever charge. ComTech Gold provides a secured server system on every occasion where any changes are made by you by accessing your account details. All the information collected by ComTech Gold is under severe security strategies framed by ComTech Gold, guarding it against unofficial access.</p>
                            <p class="mt-4 mb-2 fs-22 " style={customStyle}>Consent</p>
                            <p class="fs-18">Once you browse ComTech Gold's website or provide your details, you agree and consent to the information gathered & use of such information that you have revealed to ComTech Gold, in agreement with the Privacy Policy.</p>
                    
                    
                    
                    
                            <p class="mt-4 mb-2 fs-22 " style={customStyle}>Who we may share your information with</p>
                            <p class="mt-4 mb-2 fs-22 " style={higlight}>1.	Companies that give services to us</p>
                            <p class="fs-18">Here we mean companies that help us provide services you use and need to process details about you for this reason. We share as little information as we can and encrypt and/or make it impossible for you to be identified by the recipient where possible (for instance by using a User ID rather than your name).</p>
                            <p class="fs-18">We share your personal data with third parties that help us provide services you use or to comply with applicable laws. We will never sell your personal data to other organisations We may share your data with:</p>
                    
                    
                            <p class="fs-18">i.	Our parent company or subsidiaries</p>
                            <p class="fs-18">ii.	Business partners that provide services to us, including IT and payment services.</p>
                            <p class="fs-18">iii.	Know Your Customer (KYC) service providers that help us with identity verification or fraud checks..</p>
                            <p class="fs-18">iv.	Cloud computing power and storage providers.</p>
                            <p class="fs-18">vi.	Companies that help us with functional analytics (to help us solve technical issues with the app for instance).</p>
                            <p class="fs-18">vii.	Companies that help us with marketing (but we will not share identifiable personal data with third parties for their own direct marketing unless you give us permission, and you can opt out any time).</p>
                            <p class="fs-18">viii.	Software companies that we use for emailing you, or for processing and storing email communications with you .</p>
                            <p class="fs-18">ix.	Companies that help us with customer support.</p>
                            <p class="fs-18">x.	Companies that offer benefits or rewards through special programmes you sign up to via the app.</p>
                            <p class="fs-18">xi.	Legal or regulatory authorities where we have a legal obligation to do so or to prevent fraud.</p>
                            <p class="fs-18">We will use this information to:</p>
                            <p class="fs-18">i.	Verify your identity and make sure what you have told us is true.</p>
                            <p class="fs-18">ii.	Help detect and prevent fraud, crime, and money laundering.</p>
                            <p class="fs-18">iii.	Manage your account with us.</p>
                            <p class="fs-18">iv.	Make sure our offers are relevant for you.</p>
                            <p class="fs-18">i.	Our parent company or subsidiaries</p>
                    
                    
                            <p class="mt-4 mb-2 fs-22 " style={customStyle}>2.	Anyone you give us permission to share it with</p>
                    
                            <p class="fs-18">i.	We tell you in the app when we need your consent to share your data with other applications.</p>

                            <p class="mt-4 mb-2 fs-22 " style={customStyle}>3.	Law enforcement and other external parties. </p>

                            <p class="fs-18">i.	We may share your details with authorities that spot and stop financial crime, money laundering, terrorism, and tax evasion if the law says we have to, or if it is necessary for other reasons, or;</p>
                            <p class="fs-18">ii.	The police, courts, or dispute resolution bodies if we have to, or;</p>
                            <p class="fs-18">iii.	Other banks to help trace money if you are a victim of fraud or other crimes or if there is a dispute about a payment, or;</p>
                            <p class="fs-18">iv.	Any other third parties where necessary to meet our legal obligations.</p>
                            <p class="mt-4 mb-2 fs-22 " style={customStyle}>4.	Corporate Finance events </p>
                            <p class="fs-18">i.	We may also share your details with people or companies if there is a corporate restructure, merger, acquisition, or takeover.</p>
                           
                           
                            <p class="mt-4 mb-2 fs-22 " style={customStyle}>Retention of data</p>
                            <p class="fs-18">We keep most of your data as long as you are using your account, and for a reasonable duration thereafter to comply with the law and if we face a legal challenge. In some circumstances, like cases of anti-money laundering or fraud, or for regulatory purposes, we may keep data longer if we need to (that is in our legitimate interest) and/or the law says we have to.</p>
                            
                    
                            <p class="mt-4 mb-2 fs-22 " style={customStyle}>Your rights </p>
                            <p class="fs-18">You have the right to:</p>
                            <p class="fs-18">i.	Access the personal data we hold about you, or to get a copy of it.</p>
                            <p class="fs-18">ii.	Make us correct inaccurate or out of date personal information.</p>
                            <p class="fs-18">iii.	Ask us to delete your data unless we have a legal obligation to retain it.</p>
                            <p class="fs-18">iv.	Be informed by us if we temporarily make your data unavailable.</p>
                            <p class="fs-18">v.	Have your personal data transmitted to another third party by us at your request, providing that it’s technically feasible.</p>
                            <p class="fs-18">vi.	Request us to stop processing your data at any given time.</p>
                            <p class="fs-18">vii.	Ask us not to contact you for marketing purposes.</p>
                            <p class="fs-18">viii.	Withdraw any consent you have given us or object to processing of your personal information.</p>
                            <p class="fs-18">ix.	Ask a member of staff to review a computer-made (automated) decision.</p>
                            <p class="fs-18">Contact us at support@comtechgold.com to exercise your above right(s) over your personal data.</p>
                           
                    
                    
                            <p class="mt-4 mb-2 fs-22 " style={customStyle}>How we keep your data secure</p>
                            <p class="fs-18">Your data is stored on secure servers. We maintain reasonable security measures to safeguard your personal data from loss, interference, misuse, unauthorised access, disclosure, alteration, or destruction. We also maintain reasonable procedures to help ensure that such data is reliable for its intended use and it is accurate, complete, and up to date.</p>


                            <p class="mt-4 mb-2 fs-22 " style={customStyle}>How to make a complaint </p>
                            <p class="fs-18">If you have a complaint about how we use your personal information, please contact us through the app or send an email to support@comtechgold.com and we will do our best to fix the problem.</p>


                            <p class="mt-4 mb-2 fs-22 " style={customStyle}>Changes to this notice</p>
                            <p class="fs-18">ComTech Gold may occasionally update this Privacy Policy to reflect changes in our software and/or products as well as any legal requirements. Any changes we make to our privacy policy will be posted on our website and via the ComTech Gold app.</p>
                    
                    
                    
                        </div>
                    </div>

                    
                    
                </div>
            </section>
         
        </section>
        </div>
    </div>
  )
}

export default Privactpolicy