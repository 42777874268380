import React, { useEffect } from 'react'

const RedirectApp = () => {
    useEffect(()=>{
        function getMobileOperatingSystem() {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;
            if (/windows phone/i.test(userAgent)) {
                return "Windows Phone";
            }
            if (/android/i.test(userAgent)) {
                console.log('android', )
                window.location.href="https://play.google.com/store/apps/details?id=com.comtech"
                return "Android";
            }
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                console.log('iPhone', )
                window.location.href="https://apps.apple.com/us/app/comtech-gold/id6450447371"
                return "iOS";
            }
            return "unknown";
        }
        getMobileOperatingSystem()
                
        var headerElements = document.querySelectorAll(".header, .footer");

        // Loop through each element and set its display to "none"
        headerElements.forEach(function(element) {
            element.style.display = "none";
        });
       
    },[])
 
  return (
    <>
    
    </>
  )
}

export default RedirectApp