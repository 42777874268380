import React from 'react'

const Companyregistration = () => {
    return (
        <div>
            <section className="pt-4 pb-4 mt-5">
                <div className="main-contain mt-5">

                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-11">
                                <div className="banner-text iq-font-white wow fadeIn" data-wow-delay="0.1s">
                                    <h2 className="h2 text-primary">Company Registration</h2>
                                    <p style={{ color: "#EDB246 " }} className="iq-tw-3 fs-4 fw-bold" >ComTech - Company Registration Details.</p>
                                    <p className='fw-bold fs-18'>Call us at 800 COMTECH - 800 2668324</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container">


                        <table class="table table-bordered">
                            <thead>
                                <tr >
                                    <th className="company" style={{ width: "50%" }}>ComTech FZCO</th>
                                    <th className="company" style={{ width: "50%" }}>ComTech DMCC</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><p className='company'><strong>Dubai Integrated Economic Zones Authority </strong> in its capacity as licensing authority for all companies incorporated in DubaiAirport Free Zone Authority, hereby certifies that the <strong> ComTech FZCO </strong> was duly incorporated in <strong> Dubai Airport Free Zone </strong> as a [FZCO] or [PLC] with limited liability and registered in its companies register on the <strong> 21/02/2022 </strong> inaccordance with Dubai Law No. 16 of 2021 concerning establishment of Dubai Integrated Economic Zone Authority and Dubai Integrated Economic Zones Authority Implementing Regulations 2022.</p>
                                        <p> ComTech FZCO is registered with the license details as below:</p>

                                        <p><span className="text-bold text-uppercase">ACTIVITIES</span> - GOLD AND OTHER PRECIOUS METALS TRADING</p>
                                        <p><span className="text-bold text-uppercase">LICENSE NUMBER</span> - 05069</p>
                                        <p><span className="text-bold text-uppercase">LICENSEE</span> - ComTech FZCO</p>
                                        <p><span className="text-bold text-uppercase">TRADE NAME </span> - ComTech FZCO</p>
                                        <p><span className="text-bold text-uppercase">LEGAL STATUS  </span> - Free Zone Company (FZCO)</p>
                                        <p><span className="text-bold text-uppercase">ISSUE DATE </span> -21-Feb-2022</p>
                                        <p><span className="text-bold text-uppercase">EXPIRY DATE  </span> - 20-Feb-2024</p>
                                        <p><span className="text-bold text-uppercase">ISSUING AUTHORITY  </span> - Dubai Airport Freezone of Dubai Integrated Economic Zones Authority</p>
                                        <p><span className="text-bold text-uppercase">ADDRESS  </span> - 9WA 138-SO-22, First Floor, Dubai Airport Freezone, PO Box 123411, Dubai UAE</p>

                                    </td>
                                    <td><p className='company'> The Registrar of Companies of the <strong> Dubai Multi Commodities Centre Authority (DMCCA) </strong> hereby certifies that:</p>
                                        <strong> <p> COMTECH DMCC</p></strong>
                                        <p className='company'>  Is formed as a Company with Limited Liability on this day <strong> 31-May-2023 </strong> and that all the Requirements under the provisions of law No.(4) of 2001 & order dated May 1st, 2002 in respect of Establishing <strong> Dubai Multi Commodities Centre Authority (DMCCA)</strong> and its amendments has been satisfied.</p>
                                        <p>ComTech DMCC is registered with the license details as below:</p>
                                        <p><span className="text-bold text-uppercase">ACTIVITIES </span> - <br />1.	INFORMATION TECHNOLOGY CONSULTANTS <br />
                                            2.	NETWORK CONSULTANCIES<br />
                                            3.	INTERNET CONSULTANCY<br />
                                            4.	SOFTWARE HOUSE<br />
                                        </p>
                                        <p><span className="text-bold text-uppercase">CERTIFICATE NO. </span> - DMCC197166</p>
                                        <p><span className="text-bold text-uppercase">LICENSE NUMBER</span> - DMCC-889799</p>
                                        <p><span className="text-bold text-uppercase">Account Number </span> -407812</p>
                                        <p><span className="text-bold text-uppercase">Registration Number </span> - DMCC197166</p>
                                        <p><span className="text-bold text-uppercase">LICENSEE </span> - ComTech DMCC</p>
                                        <p><span className="text-bold text-uppercase">OPERATING NAME </span> - ComTech DMCC</p>
                                        <p><span className="text-bold text-uppercase">LEGAL STATUS </span> - Free Zone Company</p>
                                        <p><span className="text-bold text-uppercase">ISSUE DATE </span> - 26-Jun-2023</p>
                                        <p><span className="text-bold text-uppercase">EXPIRY DATE </span> - 25-Jun-2024</p>
                                        <p><span className="text-bold text-uppercase">ISSUING AUTHORITY </span> - Dubai Multi Commodities Centre Authority (DMCCA)</p>
                                        <p><span className="text-bold text-uppercase">Address</span> -15th Floor, 1509, Platinum Tower, Cluster I, Next to Almas Tower, Jumeirah Lake Towers, DMCC, Dubai, U.A.E</p>
                                    </td>

                                </tr>
                                <tr>


                                </tr>
                                <tr>


                                </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
            </section>
        </div>
    )
}

export default Companyregistration