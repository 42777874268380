import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Aboutus from './Component/Aboutus';
import Home from './Component/Home';
import Navbar from './Component/Navbar';
import Footer from './Component/Footer';
import Contactus from './Component/Contactus';
import Companyregistration from './Component/Companyregistration';
import TokenizedGoldPage from './Component/Tokenizegold';
import Tokenizegold2 from './Component/Tokenizegold2';
import Digitalgold from './Component/Digitalgold';
import CGO from './Component/CGO';
import Feestructure from './Component/Feestructure';
import Derisk from './Component/Derisk';
import Transparency from './Component/Transparency';
import Routine from './Component/Routine';
import FAQs from './Component/FAQs';
import Privacypolicy from './Component/Privacypolicy';
import Blogs from './Component/Blogs';
import RedirectApp from './Component/RedirectApp';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


function App() {
  return (

  <Router>
  <div>
    <Navbar />
    <ScrollToTop />
    <Routes>
      <Route path="/" element={<Home />} /> 
      <Route path="/Digitalgold" element={<Digitalgold />} /> 
      <Route path="/TokenizedGold" element={<TokenizedGoldPage />} /> 
      <Route path="/Exchanges&Wallets" element={<Tokenizegold2 />} /> 
      <Route path="/FAQs" element={<FAQs />} /> 
      <Route path="/Aboutus" element={<Aboutus />} /> 
      <Route path="/Blogs" element={<Blogs />} /> 

      <Route path="/Contactus" element={<Contactus />} /> 
      <Route path="/Companyregistration" element={<Companyregistration />} /> 
      <Route path="/Privacypolicy" element={<Privacypolicy />} /> 
      <Route path="/Cgolistinglibrary" element={<CGO />} /> 
      <Route path="/Feestructure" element={<Feestructure />} /> 
      <Route path="/Deriskfromstablecoins" element={<Derisk />} /> 
      <Route path="/Transparency" element={<Transparency />} /> 
      <Route path="/Routine" element={<Routine />} /> 
      <Route path="/download" element={<RedirectApp />} />
    </Routes>
    <Footer/>
  </div>
</Router>
  );
}

export default App;
