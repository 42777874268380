import React from 'react'
import { Link } from 'react-router-dom'
const FAQs = () => {
  return (
    <>
    <section className="mt-5 mb-5 " id="FAQs">
          <div className="container">
            <div className="row">
              <div className="col-12 pt-5 mt-5 mb-5">
                <div className="main_title">
                  <h2 className="h2 text-primary text-center">FAQs</h2>
                 
                </div>
              </div>

              <div className="col-12">


                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      What is ComTech Gold (CGO)?
                      </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>ComTech Gold (CGO) is an asset-backed token that represents real gold. One token represents one pure gram of gold stored with Transguard, a reputed secured Vault operator in the UAE. CGO owners have ownership rights to that gold under the custody of XDC Trust Company. CGO's value is tied directly to the real-time market value of the gold it represents.</p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      What is the benefit of having physical assets on a blockchain?
                      </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>Physical gold on the blockchain represents legal ownership of physical allocated gold, but is easier to transport and store than physical gold. Instead, CGO tokens have the divisibility, fungibility and tradability of any digital asset like bitcoin. You get the benefits of physical and digital assets at the same time.</p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      How much gold is backing the total supply of ComTech Gold (CGO)?
                      </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>The total supply of gold varies as the market capitalization of CGO increases and decreases. At all times, the amount of gold representing the total supply of CGO is in a 1:1 ratio with the number of tokens. One CGO represents one pure gram of gold.</p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSeven">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                      Can I get a fractional amount of a ComTech Gold (CGO) token?
                      </button>
                    </h2>
                    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                     <p> Yes. Token fractions are divisible out to 18 decimal points. If you purchase CGO directly from comtechgold.com, the minimum purchase is 0.01 CGO (1% of a CGO token – or roughly $15).</p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      What does allocated and unallocated gold mean?
                      </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>Allocated gold is identifiable with a unique serial number, purity, and weighting for each bar. It is kept in a vault on the owner's behalf. When you purchase a CGO token, you own a specific piece of gold with a unique serial number.</p>
                      <p>If you own unallocated gold, on the other hand, you don't actually own any specific gold bars. Instead, you have a general entitlement to a certain amount of gold. This is more like a bank- instead of owning specific bank notes, the bank owed you a certain amount of money.</p>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      What are on-chain transaction fees? Are they the same thing as 'gas'?
                      </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>Sending a CGO token from one address to another is an on-chain transaction. The token is transferred and recorded on the XDC blockchain. There are two kinds of fees that occur when you do this:
</p><ul>
<br /> <li><p>Standard 'gas' fee: Sending digital assets on XDC, requires computing power, or 'gas.' Just like any other XRC-20 token, CGO requires standard gas fees paid in XDC to compute the transaction.
                        </p></li> 
                        <br />  <li> <p>CGO on-chain transaction fee: CGO charges an additional fee. That fee is set to 0.02% of the amount of CGO sent on the blockchain. This helps us manage and take care of the gold in our vault for you.
</p><br /></li>  </ul>
<p>For example, if you want to send 10 CGO from one XRC-20 address to another, you should send at least 10.0020004001 CGO total to cover the cost of the on-chain transaction fee. If you initiated a transfer of 10 CGO, the receiver would get 9.998 CGO after fees.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                      How can I tokenize my physical gold?
                      </button>
                    </h2>
                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>ComTech Gold also Tokenized Physical Gold (standard gold bar with 999 purity).</p>
                      <ul><li>  <p>Step1: Deposit Physical Gold to Partner Custodian (like transguard.)</p>
                      </li><li> <p>Step2: Purity check by ComTech quality team.</p>
                      </li><li><p>Step3: Token allocation against Physical Gold. Process takes 24-48 hrs after deposit of physical gold to custodian like transguard.</p>
                      </li></ul> </div>
                    </div>
                  </div>
               
                </div>

              </div>
            </div>
          </div>
        </section>

        {/* <section className="mt-5 mb-5 pt-5 pb-5 tokenized_gold  tokenized_gold_box">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="bg-primary-dark gold_pattern p-4 border-radius">
                  <div className="row z-index-3 align-items-center">
                    <div className="col-lg-9 mb-2 mt-2">
                      <div className="main_title ps-lg-4">
                        <h4 className="text-lg-start text-center text-white fs-38 fw-bold">White Paper</h4>
                        <h3 className="h3 text-lg-start text-center text-secondary">Learn more about ComTech Gold (CGO) in our white paper.</h3>
                      </div>
                    </div>
                    <div className="col-lg-3 mb-2 mt-2">
                      <div className="d-flex justify-content-center">
                        <Link to="../assets/pdf/cgo_whitepaper_v1.pdf" download target='_blank' className="btn btn-outline-primary">Read More</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        </>
  )
}

export default FAQs