import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
import axios from "axios";

const Home = () => {

  // useEffect(() => {
  //   const buynowModal = document.getElementById('popup');
  //   if (buynowModal) {
  //     const modal = new window.bootstrap.Modal(buynowModal);
  //     modal.show();

  //     const closeModalAndScroll = () => {
  //       modal.hide();
  //       window.scrollTo({
  //         top: 0,
  //         behavior: 'smooth'
  //       });
  //     };

  //     buynowModal.addEventListener('click', (event) => {
  //       const clickedElement = event.target;
  //       const isOutsideModal = clickedElement === buynowModal;
  //       const isOutsideImage = !clickedElement.closest('.modal-content') || !clickedElement.closest('img');

  //       if (isOutsideModal || isOutsideImage) {
  //         closeModalAndScroll();
  //       }
  //     });
  //   }
  // }, []);

  const [value, setValue] = useState("");
  useEffect(() => {
    axios.get("https://appapi.comtechgold.com/api/setting").then((responce) => {
      setValue(responce.data)
    });
  }, [Home]);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    mobile: '',
    message: '',
    country: 'select',
  });

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    mobile: '',
    message: '',
    country: '',
  });

  const mobileRegex = /^[0-9]+$/;
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const textRegex = /^[A-Za-z]+$/;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });

    if ((name === 'firstName' || name === 'lastName') && !textRegex.test(value)) {
      setErrors({ ...errors, [name]: 'Please enter a valid name (letters only)' });
    }

    if (name === 'mobile' && !mobileRegex.test(value)) {
      setErrors({ ...errors, [name]: 'Please enter a valid mobile number' });
    }

    if (name === 'email' && !emailRegex.test(value)) {
      setErrors({ ...errors, [name]: 'Please enter a valid email address' });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = {};
    let hasErrors = false;

    for (const field in formData) {
      if (formData[field] === '' || formData[field] === 'select') {
        validationErrors[field] = 'This field is required';
        hasErrors = true;
      }
    }

    if (!textRegex.test(formData.firstName)) {
      validationErrors.firstName = 'Please enter a valid first name (letters only)';
      hasErrors = true;
    }

    if (!textRegex.test(formData.lastName)) {
      validationErrors.lastName = 'Please enter a valid last name (letters only)';
      hasErrors = true;
    }

    if (!mobileRegex.test(formData.mobile)) {
      validationErrors.mobile = 'Please enter a valid mobile number';
      hasErrors = true;
    }

    if (!emailRegex.test(formData.email)) {
      validationErrors.email = 'Please enter a valid email address';
      hasErrors = true;
    }

    if (hasErrors) {

      setErrors(validationErrors);
    } else {
      try {

        const response = await axios.post('https://appapi.comtechgold.com/api/contact/create', formData);
        //window.alert('Form submitted successfully. Thanks for writing to us, our team will contact you soon');

        if (response.status === 200) {
          window.alert('Thanks for writing to us, our team will contact you soon');
        } else {
          window.alert('Form submission failed');
        }

        setFormData({
          firstName: '',
          lastName: '',
          companyName: '',
          email: '',
          mobile: '',
          message: '',
          country: 'select',
        });
      } catch (error) {

        window.alert('Form submission failed');
      }
    }
  };
  return (

    // <div id="home">
    <main>

      <section className="banner bg-primary-light banner mt-5 pt-5">
        <div className="container mb-lg-5">
          <div class="row homepage">
            <div class="col-lg-7 mb-5 mb-lg-0">
              <div class="banner_text mb-4 ">
                <h1>Middle East’s Premier Destination for <span className="text-gradiant">Digital Gold</span></h1>

                <p className='mb-2'> In collaboration with <strong>DMCC</strong>, <strong>Brinks</strong>, <strong>Transguard</strong></p>
                <p className='mb-0'>It’s time to Unlock the Future of Wealth with <strong>ComTech</strong> Gold <strong>Seamless</strong>, <strong>Secure</strong>, and <strong>Smart</strong> Investment <strong> in Digital Gold</strong></p>

              </div>

              <div class="banner_box gradient-box">
                <div class="d-flex flex-wrap align-items-center justify-content-between w-100">
                  <div class="d-flex flex-wrap align-items-center box-top me-4">
                    <div class="box-logo me-2">
                      <img src="assets/images/banner_logo.png" />
                    </div>
                    <div class="text">
                      <h3>Buying Price</h3>
                      <h2 className="text-gradiant fw-bold m-0">AED{(parseFloat(value.buyGm) * (value.buyAedConversion)).toFixed(2)} /gm</h2>

                    </div>
                  </div>
                  <div class="ms-auto percent_live">
                    <div class="live_rate justify-content-end text-end mt-lg-3 mb-lg-3">
                      <span>LIVE RATE</span>
                    </div>
                    <div class="persent justify-content-end text-end mt-lg-3 mb-lg-3">
                      <span>24K 99.99%</span>
                    </div>
                  </div>
                </div>

                <div class="main_buy_now_wrap d-flex flex-wrap align-items-center justify-content-between w-100 mt-4">
                  <div class="inner_grams_amout d-flex flex-wrap align-items-center justify-content-between ps-lg-3 pe-lg-3 ps-2 pe-2">
                    <div class="d-flex mt-1 mb-1 flex-wrap justify-content-start w-50">
                      <span class="fw-500 d-block w-100">Buy in Grams</span>
                      <span class="fw-400 d-block w-100">10 gms</span>
                    </div>
                    <div class="d-flex mt-1 mb-1 flex-wrap justify-content-end w-50">
                      <span className="fw-500 d-block w-100 text-lg-end">Amount</span>
                      <span className="fw-400 d-block w-100 text-lg-end">AED {(parseFloat(value.buyGm) * value.buyAedConversion).toFixed(2)}/gm</span>
                    </div>
                  </div>
                  <div className="buy_now_btn">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#buynowgold">Buy Now</a>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-lg-5 mb-lg-5 ">
              <div className="banner_right">
                <img src="assets/images/Website.png" height="380px" alt="banner" />
              </div>
              <div className="mt-5 download_btn d-flex flex-wrap align-items-center justify-content-center">
                <div className="ms-2 me-2 mt-4">
                  <Link to="https://play.google.com/store/apps/details?id=com.comtech" target='blank'><img src="assets/images/google-play-badge.png" /> </Link>
                </div>
                <div className="ms-2 me-2 mt-4">
                  <Link to="https://apps.apple.com/us/app/comtech-gold/id6450447371" target='blank' style={{ position: "relative" }}><img src="assets/images/ios-badge.png" /> </Link>
                </div>
              </div>
              <div className='text-center mt-2 ms-2 me-2 '>
                <Link to='../assets/ComTechGoldAPP–Step-by-StepGuide-Updated.pdf' target='_blank' style={{ textDecoration: "none", color: "#00AB7E", fontSize: "12px" }}><p className='downloadinfo'>  <i className="fa-regular fa-asterisk fa-xs"></i> Click here to read( Step to Step Guide Updated)  </p></Link>
              </div>
            </div>

          </div>





        </div>
        
      </section>

      {/* popup box open  */}
      <div className="modal fade" id="popup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="team1Label" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg " >
          <div className="modal-content" style={{ background: "transparent", border: "0px" }}>


            <div style={{ textAlign: "center" }}>
              <Link to='assets/pdf/Ramadan_Month_Offer_T&C.pdf' target='_blank'>
                <img src="assets/images/Ramadan.png" style={{ width: "70%", margin: "auto" }} alt="" />
              </Link>
            </div>


          </div>
        </div>
      </div>


      <div className="modal fade" id="buynow" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="team1Label" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content bg-primary-light">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="modal-body">
              <div className="team_detail">
                <h2 className="fs-38 mb-0 text-white">Coming Soon</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="buynowgold" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="team1Label" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content bg-primary-light">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="modal-body">
              <div className="team_detail">
                <div className="download_btn d-flex flex-wrap align-items-center justify-content-center">
                  <h2 className="fs-26 mb-0 text-white fontsize" style={{ textAlign: "justify" }}>Unlock the world of convenience and seamless interaction with just a simple click.
                    <h2 className='fs-26 mb-0 text-white fontsize justify-content' style={{ textAlign: "center" }}>Download our app today </h2>
                  </h2>
                  <div className="ms-2 me-2 mt-4">
                    <Link to="https://play.google.com/store/apps/details?id=com.comtech" target='blank'><img src="assets/images/playstore-image.jpeg" /> </Link>
                  </div>
                  <div className="ms-2 me-2 mt-4">
                    <Link to="https://apps.apple.com/us/app/comtech-gold/id6450447371" target='blank' style={{ position: "relative" }}><img src="assets/images/iphone-image.jpeg" /> </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     

      <section class="bg-secondary-light px-80">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 mb-4">
              <div class="main_title">
                <h2 class="h2 text-center text-primary">Unlocking a Digital Future</h2>
                <h3 class="h3 text-center text-secondary">The Benefits and Advantages</h3>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-4">
              <div class="feature_box bg-primary-light d-flex flex-wrap align-items-start">
                <div class="feature_icon">
                  <img src="assets/images/shariah-compliant.png" alt="" />
                </div>
                <div class="feature_text">
                  <h4 class="h4 mb-2">Zero Making Charges</h4>
                  <p>Save on Gold making charges</p>
                </div>

              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-4">
              <div class="feature_box bg-primary-light d-flex flex-wrap align-items-start">
                <div class="feature_icon">
                  <img src="assets/images/shariah-compliant.png" alt="" />
                </div>
                <div class="feature_text">
                  <h4 class="h4 mb-2">Trade at International Gold Prices</h4>
                  <p>Benefit from direct international gold price</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-4">
              <div class="feature_box bg-primary-light d-flex flex-wrap align-items-start">
                <div class="feature_icon">
                  <img src="assets/images/shariah-compliant.png" alt="" />
                </div>
                <div class="feature_text">
                  <h4 class="h4 mb-2">Invest with smaller amount</h4>
                  <p>You can start investing with as little as 0.50 grams</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-4">
              <div class="feature_box bg-primary-light d-flex flex-wrap align-items-start">
                <div class="feature_icon">
                  <img src="assets/images/shariah-compliant.png" alt="" />
                </div>
                <div class="feature_text">
                  <h4 class="h4 mb-2">Buy / Sell Gold at a click of a button</h4>
                  <p>Buy / sell at the convenience of your home without making an effort to visit a jewellery store</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-4">
              <div class="feature_box bg-primary-light d-flex flex-wrap align-items-start">
                <div class="feature_icon">
                  <img src="assets/images/shariah-compliant.png" alt="" />
                </div>
                <div class="feature_text">
                  <h4 class="h4 mb-2">Convert easily to Physical Gold</h4>
                  <p>Flexibility to convert your digital gold into Physical Gold</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-4">
              <div class="feature_box bg-primary-light d-flex flex-wrap align-items-start">
                <div class="feature_icon">
                  <img src="assets/images/shariah-compliant.png" alt="" />
                </div>
                <div class="feature_text">
                  <h4 class="h4 mb-2">Gift your Family / Friends & Employees</h4>
                  <p>Gift Gold to your near & dear ones</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     
      <section class="bg-secondary-light bg-vector px-80 pt-0">
        <div class="pattern-layer">
          <div class="pattern-1"></div>
        </div>
        <div class="container z-index-3">
          <div class="row justify-content-center">
            <div class="col-lg-8 mb-4">
              <div class="main_title">
                <h2 class="main-title text-center mb-4 w-100">We have the Trust of Your Trusted Ones</h2>
              </div>
            </div>
          </div>
          <Swiper
            className='swiper mySwiper mb-4 z-index-3'
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              el: ".swiper-pagination",
              clickable: true
            }}
            loop={true}
            speed={2400}
            autoplay={{
              delay: 3500,

              autoplayDisableOnInteraction: true,
            }}

            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 15
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 30
              },
            }}
            modules={[Autoplay, Pagination, Navigation]}
          // onSlideChange={(swiper) => handleSlideChange(swiper)}
          >
            <div className="swiper mySwiper mb-4 z-index-3">
              <div className="swiper-wrapper">
                <SwiperSlide><div className="swiper-slide">
                  <div className="brand_icon">
                    {/* <img src="assets/images/partners/dcent-wallet.png" /> */}
                    <img src="assets/images/partners/dcent-wallet-light.png" class="img-fluid iconL" alt="" />
                    <img src="assets/images/partners/dcent-wallet.png" class="img-fluid iconD" alt="" />
                  </div>
                </div>
                </SwiperSlide> <SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    {/* <img src="assets/images/partners/globiance.png" /> */}
                    <img src="assets/images/partners/globiance-light.png" class="img-fluid iconL" alt="" />
                    <img src="assets/images/partners/globiance.png" class="img-fluid iconD" alt="" />
                  </div>
                </div>
                </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                  <div className="brand_icon">
                    {/* <img src="assets/images/partners/xdc.png" /> */}
                    <img src="assets/images/partners/xdc-light.png" class="img-fluid iconL" alt="" />
                    <img src="assets/images/partners/xdc.png" class="img-fluid iconD" alt="" />
                  </div>
                </div>
                </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                  <div className="brand_icon">
                    {/* <img src="assets/images/partners/amanie.png" /> */}
                    <img src="assets/images/partners/amanie-light.png" class="img-fluid iconL" alt="" />
                    <img src="assets/images/partners/amanie.png" class="img-fluid iconD" alt="" />
                  </div>
                </div>
                </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                  <div className="brand_icon">
                    {/* <img src="assets/images/partners/kalyan.png" /> */}
                    <img src="assets/images/partners/kalyan-light.png" class="img-fluid iconL" alt="" />
                    <img src="assets/images/partners/kalyan.png" class="img-fluid iconD" alt="" />
                  </div>
                </div>
                </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                  <div className="brand_icon">
                    {/* <img src="assets/images/partners/stonex.png" /> */}
                    <img src="assets/images/partners/stonex-light.png" class="img-fluid iconL" alt="" />
                    <img src="assets/images/partners/stonex.png" class="img-fluid iconD" alt="" />
                  </div>
                </div>
                </SwiperSlide>
                <SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    {/* <img src="assets/images/partners/transguard.png" /> */}
                    <img src="assets/images/partners/transguard-light.png" class="img-fluid iconL" alt="" />
                    <img src="assets/images/partners/transguard.png" class="img-fluid iconD" alt="" />
                  </div>
                </div>
                </SwiperSlide><SwiperSlide>

                  <div className="swiper-slide">
                    <div className="brand_icon">
                      {/* <img src="assets/images/partners/Brinks.png" /> */}
                      <img src="assets/images/partners/brinks-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/partners/brinks.png" class="img-fluid iconD" alt="" />
                    </div>
                  </div>
                </SwiperSlide><SwiperSlide>

                  <div className="swiper-slide">
                    <div className="brand_icon">
                      {/* <img src="assets/images/partners/Fab.png" /> */}
                      <img src="assets/images/partners/fab-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/partners/fab.png" class="img-fluid iconD" alt="" />
                    </div>
                  </div>
                </SwiperSlide><SwiperSlide>

                  <div className="swiper-slide">
                    <div className="brand_icon">
                      {/* <img src="assets/images/partners/Zand.png" /> */}
                      <img src="assets/images/partners/zand-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/partners/zand.png" class="img-fluid iconD" alt="" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>

                  <div className="swiper-slide">
                    <div className="brand_icon">
                      {/* <img src="assets/images/partners/dmcc.png" /> */}
                      <img src="assets/images/partners/dmcc-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/partners/dmcc.png" class="img-fluid iconD" alt="" />
                    </div>
                  </div>
                </SwiperSlide><SwiperSlide>

                  <div className="swiper-slide">
                    <div className="brand_icon">
                      {/* <img src="assets/images/partners/yodaplus.png" /> */}
                      <img src="assets/images/partners/yodaplus-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/partners/yodaplus.png" class="img-fluid iconD" alt="" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>

                  <div className="swiper-slide">
                    <div className="brand_icon">
                      {/* <img src="assets/images/partners/propine.png" /> */}
                      <img src="assets/images/partners/propine-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/partners/propine.png" class="img-fluid iconD" alt="" />
                    </div>
                  </div>
                </SwiperSlide>
              </div>
            </div> <br />
          </Swiper>
        </div>


      </section>

      <section className=" bg-primary-light px-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mb-0">
              <div className="main_title">
                <h5 className="h5 text-secondary mb-0">GET IN TOUCH</h5>
                <h2 className="h2 text-primary">Contact Us</h2>
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <div className="contact_left_box bg-secondary-light">
                <h5>Ready to discover more about ComTech Gold?</h5>
                <p>Please click Contact Us button below and fill up the form.</p>
                <div className="mt-5">
                  <a
                    className="btn btn-secondary"
                    data-bs-toggle="modal"
                    data-bs-target="#team1"
                  >
                    Contact Us Now
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <div className="contact_left_box bg-secondary-light">
                <div className="d-flex flex-wrap get_in_touch">
                  <div className="add_icon">
                    <img src="assets/images/address_icon.png" />
                  </div>
                  <div className="contact_text">
                    <h4 className="h4 mb-2">Address</h4>
                    <p>15th Floor, 1509, Platinum Tower, Cluster I, Next to Almas Tower, Jumeira Lake Tower, DMCC, Dubai, U.A.E.</p>
                  </div>
                </div>
                <div className="d-flex flex-wrap get_in_touch">
                  <div className="add_icon">
                    <img src="assets/images/main_icon.png" />
                  </div>
                  <div className="contact_text">
                    <h4 className="h4 mb-0">Mail</h4>
                    <p className="h5"><a href="mailto: info@comtechgold.com" style={{ color: "rgb(6 75 56 / 60%)" }}>info@comtechgold.com</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal fade"
        id="team1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="team1Label"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
          <div className="modal-content bg_primary_gradiant">
            <button
              type="button"
              className="btn-close btn-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-body">
              <div className="team_detail p-4">
                <div className="sub_name">
                  <span className="fs-22 text-gradiant">GET IN TOUCH</span>{' '}
                </div>
                <h2 className="fs-38 mb-0 text-white">Contact Us</h2>

                <div className="col-md-12 mt-4">
                  <div className="contact_form contact_left_box p-4">
                    <h5 className="text-white">
                      Ready to discover more about ComTech Gold?
                    </h5>

                    <form className="mt-3" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12 mb-4">
                          <input
                            type="text"
                            className={`form-control ${errors.firstName ? 'is-invalid' : ''
                              }`}
                            placeholder="First Name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            autoComplete="off"

                          />
                          {errors.firstName && (
                            <div className="invalid-feedback">
                              {errors.firstName}
                            </div>
                          )}
                        </div>
                        <div className="col-12 mb-4">
                          <input
                            type="text"
                            className={`form-control ${errors.lastName ? 'is-invalid' : ''
                              }`}
                            placeholder="Last Name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            autoComplete="off"

                          />
                          {errors.lastName && (
                            <div className="invalid-feedback">
                              {errors.lastName}
                            </div>
                          )}
                        </div>

                        <div className="col-12 mb-4">
                          <input
                            type="text"
                            className={`form-control ${errors.companyName ? 'is-invalid' : ''
                              }`}
                            name="companyName"
                            placeholder="Company Name"
                            value={formData.companyName}
                            onChange={handleChange}
                            autoComplete="off"

                          />
                          {errors.companyName && (
                            <div className="invalid-feedback">
                              {errors.companyName}
                            </div>
                          )}
                        </div>
                        <div className="col-12 mb-4">
                          <input
                            type="text"
                            className={`form-control ${errors.email ? 'is-invalid' : ''
                              }`}
                            name="email"
                            placeholder="Email ID"
                            value={formData.email}
                            onChange={handleChange}
                            autoComplete="off"

                          />
                          {errors.email && (
                            <div className="invalid-feedback">{errors.email}</div>
                          )}
                        </div>
                        <div className="col-12 mb-4">
                          <input
                            type="text"
                            className={`form-control ${errors.mobile ? 'is-invalid' : ''
                              }`}
                            name="mobile"
                            placeholder="mobile No"
                            value={formData.mobile}
                            onChange={handleChange}
                            autoComplete="off"

                          />
                          {errors.mobile && (
                            <div className="invalid-feedback">{errors.mobile}</div>
                          )}
                        </div>
                        <div className="col-12 mb-4">
                          <input
                            type="text"
                            className={`form-control ${errors.message ? 'is-invalid' : ''
                              }`}
                            name="message"
                            placeholder="Message"
                            value={formData.message}
                            onChange={handleChange}
                            autoComplete="off"

                          />
                          {errors.message && (
                            <div className="invalid-feedback">{errors.message}</div>
                          )}
                        </div>
                        <div className="col-lg-6 mb-4">
                          <select
                            className={`form-select ${errors.country ? 'is-invalid' : ''
                              }`}
                            id="country"
                            name="country"
                            value={formData.country}
                            onChange={handleChange}

                          >
                            <option value="select">select country</option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="Åland Islands">Åland Islands</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="American Samoa">American Samoa</option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antarctica">Antarctica</option>
                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Aruba">Aruba</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijan">Azerbaijan</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahrain">Bahrain</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Benin">Benin</option>
                            <option value="Bermuda">Bermuda</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia">Bolivia</option>
                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                            <option value="Botswana">Botswana</option>
                            <option value="Bouvet Island">Bouvet Island</option>
                            <option value="Brazil">Brazil</option>
                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canada">Canada</option>
                            <option value="Cape Verde">Cape Verde</option>
                            <option value="Cayman Islands">Cayman Islands</option>
                            <option value="Central African Republic">Central African Republic</option>
                            <option value="Chad">Chad</option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Christmas Island">Christmas Island</option>
                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Congo">Congo</option>
                            <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                            <option value="Cook Islands">Cook Islands</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Cote D'ivoire">Cote D'ivoire</option>
                            <option value="Croatia">Croatia</option>
                            <option value="Cuba">Cuba</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czech Republic">Czech Republic</option>
                            <option value="Denmark">Denmark</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">Dominican Republic</option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="Egypt">Egypt</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                            <option value="Faroe Islands">Faroe Islands</option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="French Guiana">French Guiana</option>
                            <option value="French Polynesia">French Polynesia</option>
                            <option value="French Southern Territories">French Southern Territories</option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Gibraltar">Gibraltar</option>
                            <option value="Greece">Greece</option>
                            <option value="Greenland">Greenland</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guadeloupe">Guadeloupe</option>
                            <option value="Guam">Guam</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guernsey">Guernsey</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guinea-bissau">Guinea-bissau</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                            <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hong Kong">Hong Kong</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="India">India</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                            <option value="Iraq">Iraq</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Isle of Man">Isle of Man</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Japan">Japan</option>
                            <option value="Jersey">Jersey</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                            <option value="Korea, Republic of">Korea, Republic of</option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Macao">Macao</option>
                            <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">Marshall Islands</option>
                            <option value="Martinique">Martinique</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mayotte">Mayotte</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                            <option value="Moldova, Republic of">Moldova, Republic of</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montenegro">Montenegro</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar</option>
                            <option value="Namibia">Namibia</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Nepal">Nepal</option>
                            <option value="Netherlands">Netherlands</option>
                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                            <option value="New Caledonia">New Caledonia</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Niger">Niger</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="Niue">Niue</option>
                            <option value="Norfolk Island">Norfolk Island</option>
                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau">Palau</option>
                            <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">Papua New Guinea</option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Philippines">Philippines</option>
                            <option value="Pitcairn">Pitcairn</option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Reunion">Reunion</option>
                            <option value="Romania">Romania</option>
                            <option value="Russian Federation">Russian Federation</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="Saint Helena">Saint Helena</option>
                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                            <option value="Saint Lucia">Saint Lucia</option>
                            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                            <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                            <option value="Samoa">Samoa</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Serbia">Serbia</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra Leone">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Slovakia">Slovakia</option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">Solomon Islands</option>
                            <option value="Somalia">Somalia</option>
                            <option value="South Africa">South Africa</option>
                            <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                            <option value="Spain">Spain</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="Sudan">Sudan</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                            <option value="Swaziland">Swaziland</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                            <option value="Taiwan">Taiwan</option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Timor-leste">Timor-leste</option>
                            <option value="Togo">Togo</option>
                            <option value="Tokelau">Tokelau</option>
                            <option value="Tonga">Tonga</option>
                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Emirates">United Arab Emirates</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="United States">United States</option>
                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                            <option value="Uruguay">Uruguay</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Venezuela">Venezuela</option>
                            <option value="Viet Nam">Viet Nam</option>
                            <option value="Virgin Islands, British">Virgin Islands, British</option>
                            <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                            <option value="Wallis and Futuna">Wallis and Futuna</option>
                            <option value="Western Sahara">Western Sahara</option>
                            <option value="Yemen">Yemen</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                          </select>
                          {errors.country && (
                            <div className="invalid-feedback">{errors.country}</div>
                          )}
                        </div>

                        <div className="col-12 mt-4">
                          <button type="submit" className="btn btn-secondary">
                            Contact Us Now
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="bg-secondary-light bg-vector px-80">
        <div class="pattern-layer">
          <div class="pattern-1"></div>
        </div>
        <div class="container z-index-3">
          <div class="row justify-content-center">
            <div class="col-lg-8 mb-4">
              <div class="main_title">
                <h2 class="main-title text-center mb-4 w-100">In The Media</h2>
              </div>
            </div>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              el: ".swiper-pagination",
              clickable: true
            }}
            loop={false}
            speed={2400}
            autoplay={{
              delay: 3500
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 15
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 30
              },
            }}
            modules={[Autoplay, Pagination, Navigation]}
          >
            <div className="swiper mySwiper mb-4 z-index-3">
              <div className="swiper-wrapper">
                <SwiperSlide><div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://n.yam.com/Article/20220927339130" target='_blank'>
                      {/* <img src="assets/images/media/yamnews.png" /> */}
                      <img src="assets/images/media/yamnews-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/media/yamnews.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide> <SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://ifnfintech.com/halal-gold-crypto-universe-expands-with-new-certified-token" target='_blank'>
                      {/* <img src="assets/images/media/ifnfintech.png" /> */}
                      <img src="assets/images/media/ifnfintech-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/media/ifnfintech.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://gulfnews.com/business/property/dubai-free-zone-dmcc-tokenises-gold-trade-through-comtech-deal-1.92159845" target='_blank'>
                      {/* <img src="assets/images/media/gulfnews.png" /> */}
                      <img src="assets/images/media/gulfnews-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/media/gulfnews.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://cointelegraph.com/news/what-is-comtech-gold-cgo-and-how-does-it-work" target='_blank'>
                      {/* <img src="assets/images/media/cointelegraph.png" /> */}
                      <img src="assets/images/media/cointelegraph-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/media/cointelegraph.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://www.tradingview.com/news/cointelegraph:59660e4fb:0" target='_blank'>
                      {/* <img src="assets/images/media/tradingview.png" /> */}
                      <img src="assets/images/media/tradingview-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/media/tradingview.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://www.khaleejtimes.com/cryptocurrency/fully-gold-back-token-to-serve-islamic-investors" target='_blank'>
                      {/* <img src="assets/images/media/khaleejtimes.png" /> */}
                      <img src="assets/images/media/khaleejtimes-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/media/khaleejtimes.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://www.nasdaq.com/press-release/the-fully-gold-backed-token-comtech-gold-cgo-is-now-available-for-trading-on-lbank" target='_blank'>
                      {/* <img src="assets/images/media/nasdaq.png" /> */}
                      <img src="assets/images/media/nasdaq-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/media/nasdaq.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide>
                <SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://www.businesswire.com/news/home/20220926005591/en/ComTech-Gold-CGO-Becomes-the-First-100-Gold-Backed-Token-to-Receive-Shariah-Certification-in-the-MENA-Region" target='_blank'>
                      {/* <img src="assets/images/media/businesswire.png" /> */}
                      <img src="assets/images/media/businesswire-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/media/businesswire.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide>
                <SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://www.livebitcoinnews.com/the-fully-gold-backed-token-comtech-gold-cgo-is-now-available-for-trading-on-lbank-exchange" target='_blank'>
                      {/* <img src="assets/images/media/livebitcoinnews.png" /> */}
                      <img src="assets/images/media/livebitcoinnews-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/media/livebitcoinnews.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide>
                <SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://www.dmcc.ae/news/dmcc-digitises-gold-trading-through-tokenisation-comtech-gold-bullion-backed-dmccs-tradeflow-platform" target='_blank'>
                      {/* <img src="assets/images/media/dmcc.png" /> */}
                      <img src="assets/images/media/dmcc-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/media/dmcc.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://laraontheblock.com/uae-gold-tokenization-pioneers-applaud-hsbc-gold-tokenization-initiative-furthering-growth" target='_blank'>
                      {/* <img src="assets/images/media/laraontheblock.png" /> */}
                      <img src="assets/images/media/laraontheblock-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/media/laraontheblock.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://coinchapter.com/globiance-and-comtech-gold-dubai-to-offer-gold-and-silver-backed-tokens" target='_blank'>
                      {/* <img src="assets/images/cc-logo-onlight.png" /> */}
                      <img src="assets/images/media/coinchapter-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/media/coinchapter.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide>

                <SwiperSlide><div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://n.yam.com/Article/20220927339130" target='_blank'>
                      {/* <img src="assets/images/media/yamnews.png" /> */}
                      <img src="assets/images/media/yamnews-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/media/yamnews.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide> <SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://ifnfintech.com/halal-gold-crypto-universe-expands-with-new-certified-token" target='_blank'>
                      {/* <img src="assets/images/media/ifnfintech.png" /> */}
                      <img src="assets/images/media/ifnfintech-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/media/ifnfintech.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://gulfnews.com/business/property/dubai-free-zone-dmcc-tokenises-gold-trade-through-comtech-deal-1.92159845" target='_blank'>
                      {/* <img src="assets/images/media/gulfnews.png" /> */}
                      <img src="assets/images/media/gulfnews-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/media/gulfnews.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://cointelegraph.com/news/what-is-comtech-gold-cgo-and-how-does-it-work" target='_blank'>
                      {/* <img src="assets/images/media/cointelegraph.png" /> */}
                      <img src="assets/images/media/cointelegraph-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/media/cointelegraph.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://www.tradingview.com/news/cointelegraph:59660e4fb:0" target='_blank'>
                      {/* <img src="assets/images/media/tradingview.png" /> */}
                      <img src="assets/images/media/tradingview-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/media/tradingview.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://www.khaleejtimes.com/cryptocurrency/fully-gold-back-token-to-serve-islamic-investors" target='_blank'>
                      {/* <img src="assets/images/media/khaleejtimes.png" /> */}
                      <img src="assets/images/media/khaleejtimes-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/media/khaleejtimes.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide>  <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://www.nasdaq.com/press-release/the-fully-gold-backed-token-comtech-gold-cgo-is-now-available-for-trading-on-lbank" target='_blank'>
                      {/* <img src="assets/images/media/nasdaq.png" /> */}
                      <img src="assets/images/media/nasdaq-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/media/nasdaq.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide>
                <SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://www.businesswire.com/news/home/20220926005591/en/ComTech-Gold-CGO-Becomes-the-First-100-Gold-Backed-Token-to-Receive-Shariah-Certification-in-the-MENA-Region" target='_blank'>
                      {/* <img src="assets/images/media/businesswire.png" /> */}
                      <img src="assets/images/media/businesswire-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/media/businesswire.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide>
                <SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://www.livebitcoinnews.com/the-fully-gold-backed-token-comtech-gold-cgo-is-now-available-for-trading-on-lbank-exchange" target='_blank'>
                      {/* <img src="assets/images/media/livebitcoinnews.png" /> */}
                      <img src="assets/images/media/livebitcoinnews-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/media/livebitcoinnews.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide>
                <SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://www.dmcc.ae/news/dmcc-digitises-gold-trading-through-tokenisation-comtech-gold-bullion-backed-dmccs-tradeflow-platform" target='_blank'>
                      {/* <img src="assets/images/media/dmcc.png" /> */}
                      <img src="assets/images/media/dmcc-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/media/dmcc.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://laraontheblock.com/uae-gold-tokenization-pioneers-applaud-hsbc-gold-tokenization-initiative-furthering-growth" target='_blank'>
                      {/* <img src="assets/images/media/laraontheblock.png" /> */}
                      <img src="assets/images/media/laraontheblock-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/media/laraontheblock.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide><SwiperSlide> <div className="swiper-slide">
                  <div className="brand_icon">
                    <a href="https://coinchapter.com/globiance-and-comtech-gold-dubai-to-offer-gold-and-silver-backed-tokens" target='_blank'>
                      {/* <img src="assets/images/cc-logo-onlight.png" /> */}
                      <img src="assets/images/media/coinchapter-light.png" class="img-fluid iconL" alt="" />
                      <img src="assets/images/media/coinchapter.png" class="img-fluid iconD" alt="" />
                    </a>
                  </div>
                </div>
                </SwiperSlide>
              </div>
            </div> <br />
          </Swiper>
        </div>
      </section>

      <section class="bg-primary-light px-80">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 mb-4">
              <h2 class="h2 text-primary choose_text">Trending Products</h2>
            </div>
          </div>
          <div class="row g-2 p-0">
            <div class="col-lg-3 col-md-6">
              <div class="box_green_hover saving_ac_box bg-secondary-light p-3 pt-4 pb-4 mb-2">
                <div class="product_icon mb-3 text-center">
                  <img src="assets/images/trending-products/product_1.png" alt="" />
                </div>
                <div class="product_detail text-center mb-3">
                  <h4>Valcambi 1 Gram Gold Coin</h4>
                  <p class="text-center">24k (999.9)</p>
                </div>
                <div class="buy_btn d-flex justify-content-center mt-3 ps-3 pe-3">
                  <button class="btn btn-secondary w-100" data-bs-toggle="modal" data-bs-target="#buynow">Buy Now</button>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="box_green_hover saving_ac_box bg-secondary-light p-3 pt-4 pb-4 mb-2">
                <div class="product_icon mb-3 text-center">
                  <img src="assets/images/trending-products/product_2.png" alt="" />
                </div>
                <div class="product_detail text-center mb-3">
                  <h4>100 Gram Gold Mint Bar</h4>
                  <p class="text-center">24k (995.0)</p>
                </div>
                <div class="buy_btn d-flex justify-content-center mt-3 ps-3 pe-3">
                  <button class="btn btn-secondary w-100" data-bs-toggle="modal" data-bs-target="#buynow">Buy Now</button>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="box_green_hover saving_ac_box bg-secondary-light p-3 pt-4 pb-4 mb-2">
                <div class="product_icon mb-3 text-center">
                  <img src="assets/images/trending-products/product_3.png" alt="" />
                </div>
                <div class="product_detail text-center mb-3">
                  <h4>1 Kilo Gold Mint Bar</h4>
                  <p class="text-center">24k (999.9)</p>
                </div>
                <div class="buy_btn d-flex justify-content-center mt-3 ps-3 pe-3">
                  <button class="btn btn-secondary w-100" data-bs-toggle="modal" data-bs-target="#buynow">Buy Now</button>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="box_green_hover saving_ac_box bg-secondary-light p-3 pt-4 pb-4 mb-2">
                <div class="product_icon mb-3 text-center">
                  <img src="assets/images/trending-products/product_4.png" alt="" />
                </div>
                <div class="product_detail text-center mb-3">
                  <h4>Valcambi 1 Gram Gold</h4>
                  <p class="text-center">24k (999.9)</p>
                </div>
                <div class="buy_btn d-flex justify-content-center mt-3 ps-3 pe-3">
                  <button class="btn btn-secondary w-100" data-bs-toggle="modal" data-bs-target="#buynow">Buy Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    // </div>

  )
}

export default Home
