import React from 'react'

const Derisk = () => {
  return (
    <div>
        <section className="pt-5 pb-5 mt-5 tokenized_gold tokenized_gold_color gold_pattern">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 mt-5 mb-4">
              <div className="main_title">
                <h2 className="h2 text-primary">De-Risk from Stablecoins</h2>
                <h3 className="h3 text-secondary">Use ComTech Gold (CGO) for your assets liquidity pairing.</h3>
                <h4 className="h4  text-center mt-4" style={{color:"black"}}>Problems with Stablecoins</h4>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="bg-light border-radius p-3 h-100 text-center">
                <p className="m-0">Fall of Terra/UST</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="bg-light border-radius p-3 h-100 text-center">
                <p className="m-0">De-pegging of USDT</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="bg-light border-radius p-3 h-100 text-center">
                <p className="m-0">Risks associated with Stablecoins</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="bg-light border-radius p-3 h-100 text-center">
                <p className="m-0">Leveraged capital</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="bg-light border-radius p-3 h-100 text-center">
                <p className="m-0">Limited Banking hours support</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="bg-light border-radius p-3 h-100 text-center">
                <p className="m-0">Lack of Audit on Cash Reserve</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="bg-light border-radius p-3 h-100 text-center">
                <p className="m-0">Limited banking support</p>
              </div>
            </div>
          </div>
        </div>  
      </section>

      {/* <section className="mb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-3">
              <h3 className="text-primary1 text-center h3">How do you Protect your project?</h3>
            </div>
            <div className="col-12 mb-2">
              <div className="border-radius p-2 border-primary text-primary1s border" >
                <p className="m-0 text-center">Dangerously linked to Stablecoins</p>
              </div>
            </div>
            <div className="col-12 mb-2">
              <div className="border-radius p-2 border-primary text-primary1s border">
                <p className="m-0 text-center">Dangerously linked to Central Bank Monetary Policies</p>
              </div>
            </div>
            <div className="col-12 mb-2">
              <div className="border-radius p-2 border-primary text-primary1s border">
                <p className="m-0 text-center">Too much correlation to Global capital markets</p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

<section class="bg-secondary-light tokenized_gold px-80">
        <div class="container">
          <div class="row">
            <div class="main_title mb-5">
                <h2 class="h2 text-primary">How do you Protect your project?</h2>
              </div>
            <div class="col-lg-4 col-md-4 mb-2">
              <div class="feature_box border-radius p-2 border-primary text-primary border">
                <p class="m-0 text-center">Dangerously linked to Stablecoins</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 mb-2">
              <div class="feature_box border-radius p-2 border-primary text-primary border">
                <p class="m-0 text-center">Dangerously linked to Central Bank Monetary Policies</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 mb-2">
              <div class="feature_box border-radius p-2 border-primary text-primary border">
                <p class="m-0 text-center">Too much correlation to Global capital markets</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="bg-primary-light tokenized_gold px-80">
        <div class="container">
        
        <div class="row justify-content-center">
        	<div class="main_title">
                <h2 class="h2 text-start text-primary">How do you Protect your project?</h2>
              </div>
          </div>
          <div class="row justify-content-center mt-4">
                        <div class="col-lg-12">
                            <ul class="white_list m-0 p-0">
                                    <li>Gold is a decentralized asset class</li>
                                    <li>Gold is a bearer asset</li>
                                    <li>ComTech Gold is backed by fully backed gold (See audit Proof)</li>
                                    <li>Maintaining liquidity with ComTech Gold (CGO) of your project will de-risk from stablecoins</li>
                                    <li>De-risk from fluctuations of capital markets</li>
                                    <li>Directly redeem gold via regulated channels as min 1 KG bars. A Gold bar can be delivered to most the countries worldwide with small logistic fees</li>
                                    <li>Mint Gold via regulated channels as min 1 KG bars</li>
                                  </ul>
                        </div>
                    </div>
          
        </div>
      </section>
      {/* <section className="mt-5 mb-5 pt-5 pb-5 bg-primary-dark">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="main_title gold_color">
                <h6>How ComTech Gold (CGO) can help?</h6>
              </div>

              <ul className="white_list m-0 p-0">
                <li>Gold is a decentralized asset class</li>
                <li>Gold is a bearer asset</li>
                <li>ComTech Gold is backed by fully backed gold (See audit Proof)</li>
                <li>Maintaining liquidity with ComTech Gold (CGO) of your project will de-risk from stablecoins</li>
                <li>De-risk from fluctuations of capital markets</li>
                <li>Directly redeem gold via regulated channels as min 1 KG bars. A Gold bar can be delivered to most the countries worldwide with small logistic fees</li>
                <li>Mint Gold via regulated channels as min 1 KG bars</li>
              </ul>

            </div>  
          </div>
        </div>
      </section> */}
    </div>
  )
}

export default Derisk