import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

const Blogs = () => {
  // const navigate = useNavigate();
const [toggle, setToggle] = useState(false);

  function handleClick() {
    setToggle(!toggle)
    window.scrollTo(0, 0);
    // navigate("/blogs1");
  }
  return (
    <div>
      <main>
        <section className=" pt-5 mt-5 mb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mt-2">
                <div className="main_title">
                  <h2 className="h2 text-primary">Blogs</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        {!toggle && (
<section className='container'>
    <div className='d-md-flex gap-3' >
    <div className='feature_box-shadow' >
    
    <img class="mx-auto d-block p-5" src="assets/images/Blog1.png"  />

    <div>
        <h1 className='fs-18 text-primary ms-4 fw-bold'>Securing Your Financial Tomorrow: <br/> Thriving with Digital Gold Investments</h1>
        
        <p className='m-4' >In an age where change is the only constant and financial uncertainties linger, the importance of futureproofing our finances has never been more apparent. The traditional paths of investment are shifting, making way for a new, tech-savvy approach – digital gold investments.</p>
        <p className='fs-18 ms-4 fw-bold' onClick={handleClick}> <span style={{cursor:"pointer"}}>Read More </span>&gt;</p>
    </div>
    </div>

     
  
    <div className='feature_box-shadow' >
    
    <img class="mx-auto d-block p-5" src="assets/images/Blog2.png"  />

    <div>
        <h1 className='fs-18 text-primary ms-4 fw-bold'>Securing Your Financial Tomorrow:</h1>
        <h1 className='fs-18 text-primary  ms-4 fw-bold'>Thriving with Digital Gold Investments</h1>
        <p className='m-4' >In an age where change is the only constant and financial uncertainties linger, the importance of futureproofing our finances has never been more apparent. The traditional paths of investment are shifting, making way for a new, tech-savvy approach – digital gold investments.</p>
        <p className='fs-18 ms-4 fw-bold' onClick={handleClick}> <span style={{cursor:"pointer"}}>Read More </span>&gt;</p>
    </div>
    </div>

    </div>
    <div className="read_more_btn d-flex align-items-center justify-content-center mt-5 mb-5">
                  <button className="btn btn-primary d-flex align-items-center" >
                 See More
                    <img
                      src={"assets/images/down_arrow.svg"}
                      className="ms-4"
                     
                    />
                  </button>
                </div>
</section>
        )}



      </main>

      { toggle && (
      <div>

        <section className='container' style={{width:"70%"}}>
          <div className='' >
            <div className='feature_box-shadow mt-5 mb-5' >

              <img class="mx-auto d-block p-5" src="assets/images/Blog1.png" />
              <hr />
              <div>
                <h1 className='fs-18 ms-4 fw-bold'>"Securing Your Financial Tomorrow: Thriving with Digital Gold Investments" </h1>
                <p className='ms-4'>In an age where change is the only constant and financial uncertainties linger, the importance of futureproofing our finances has never been more apparent. The traditional paths of investment are shifting, making way for a new, tech-savvy approach – digital gold investments. This revolutionary strategy holds the promise of safeguarding wealth and ensuring a prosperous financial future.</p>
              </div>

              <div>
                <h1 className='fs-18 ms-4 fw-bold'>Embarking on the Digital Gold Journey:</h1>
                <p className='ms-4'><span className='text-primary'>Picture this:</span> digital gold, a term that has become synonymous with cryptocurrencies, acting as a modern-day guardian for your hard-earned money. At the forefront of this digital financial revolution is Bitcoin, affectionately known as "digital gold." Its allure lies in its decentralized nature, limited supply, and the robust security that comes with cryptographic technology – qualities that resonate with investors seeking stability amidst economic turbulence.</p>
              </div>

              <div>
                <h1 className='fs-18 ms-4 fw-bold'> The Upsides of Embracing Digital Gold:</h1>
                <p className='ms-4'><span className='text-primary fw-bold'>Guarding Against Inflation:</span> In a world where money seems to multiply endlessly, digital gold provides a finite and protected alternative, shielding investors from the erosive clutches of inflation.</p>
<br />
                <p className='ms-4'><span className='text-primary fw-bold'>Democratizing Finance:</span> Digital gold investments break down geographical barriers, offering a universal opportunity for individuals worldwide to participate in a financial landscape without borders.</p>

                <p className='ms-4'><span className='text-primary fw-bold'>Built on Trust: </span>The transparent and secure nature of blockchain technology, the backbone of digital gold, fosters trust. Investors can be confident in the authenticity and integrity of their transactions.</p>

                <p className='ms-4'> <span className='text-primary fw-bold'>Diversification for Stability:</span> Including digital gold in your investment portfolio is akin to adding a resilient shield. Its performance often remains independent of traditional market trends, providing a buffer against market volatility.</p>

                <p className='ms-4'><span className='text-primary fw-bold'> Potential for Growth: </span>History tells a compelling story of digital gold's remarkable price appreciation, offering investors the exciting potential for substantial returns over time.</p>

                <p className='ms-4'><span className='text-primary fw-bold'>Navigating the Digital Seas: </span> Yet, as with any venture, there are challenges to navigate. The digital gold realm is not exempt, with volatility, regulatory uncertainties, and technological vulnerabilities posing potential hurdles. Awareness and careful consideration of these risks are vital for any investor.</p>

              </div>

              <div>
                <h1 className='fs-18 ms-4 fw-bold'>Wise Strategies for Digital Gold Investments:</h1>

                <p className='ms-4'><span className='text-primary fw-bold'>  Knowledge is Power: </span> Arm yourself with a solid understanding of blockchain technology, the dynamics of cryptocurrency markets, and the specific digital gold asset you're eyeing. Informed decisions are the bedrock of successful investments.</p>

                <p className='ms-4'><span className='text-primary fw-bold'> Smart Investment Practices:</span>  Mitigate the impact of market fluctuations by adopting a dollar-cost averaging strategy. Spread your investment over time, easing the impact of market volatility.</p>

                <p className='ms-4'><span className='text-primary fw-bold'> Safekeeping Matters: </span> Choose reputable cryptocurrency wallets and exchanges to safeguard your digital gold assets. Consider the added layer of security provided by hardware wallets, keeping your investments offline and secure.</p>

                <p className='ms-4'><span className='text-primary fw-bold'> Navigate Regulations:</span>  Keep a watchful eye on regulatory developments in your region. Compliance with legal frameworks ensures a secure and stable investment environment.</p>

                <p className='ms-4'> As we navigate the ever-evolving financial landscape, embracing digital gold investments emerges as a beacon of hope. This modern approach allows investors to diversify portfolios, manage risks, and position themselves for a prosperous future in a world that's constantly changing. In the pursuit of financial security, digital gold becomes more than an investment – it's a bridge to a resilient and adaptable future, securing not just wealth, but the promise of a brighter financial tomorrow.</p>

              </div>
              <p className='fs-18 ms-4 fw-bold' onClick={handleClick}> <span style={{cursor:"pointer"}}>Read Less </span>&gt;</p>
            </div>



          </div>

        </section>



    </div>
      )}
    </div>





  )
}

export default Blogs;