import React from 'react'

const Feestructure = () => {
  return (
    <div>
          <main>
      
      <section className="pt-5 pb-5 mt-5 tokenized_gold tokenized_gold_color_section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 mt-5 mb-4">
              <div className="main_title">
                <h2 className="h2 text-primary">Fee Structure</h2>
                <h3 className="h3 text-secondary">ComTech Gold (CGO) Fee Structure.</h3>
              </div>
            </div>
            <div className="col-12 mb-4">
              <div className="table-responsive mt-4 bg-primary-dark gold_pattern">
                <table className="table table-dark">
                  <thead>
                    <tr>
                      <th scope="col">Fees</th>
                      <th scope="col">Rate</th>
                      <th scope="col">Charged To</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="3" > </td>
                    </tr>
                    <tr>
                      <td>Minting/Creation of Tokens</td>
                      <td>0.50%</td>
                      <td>Token Owner depositing the Bar</td>
                    </tr>
                    <tr>
                      <td colSpan="3" className="p-0"><img className="w-100" src="assets/images/footer_line.png" /> </td>
                    </tr>
                    <tr>
                      <td>Inventory under Custody</td>
                      <td>0.1% p.a.</td>
                      <td>Token Owner based on holding period (Free for the first Year)</td>
                    </tr>
                    <tr>
                      <td colSpan="3" className="p-0"><img className="w-100" src="assets/images/footer_line.png" /> </td>
                    </tr>
                    <tr>
                      <td>Burning/Cancellation of Tokens</td>
                      <td>0.50%</td>
                      <td>Token Owner requesting for Cancellation against Physical Gold</td>
                    </tr>
                    <tr>
                      <td colSpan="3" className="p-0"><img className="w-100" src="assets/images/footer_line.png" /> </td>
                    </tr>
                    <tr>
                      <td>Trading Fees</td>
                      <td>0.10% to 0.40%<br/>(Based on Exchange traded)</td>
                      <td>Levied by the Exchange where these tokens are listed for trading</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>  
      </section>

    </main>
    </div>
  )
}

export default Feestructure