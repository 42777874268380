import React from 'react'
import { Link } from 'react-router-dom'
const CGO = () => {
  return (
    <div>
          <section className="pt-5 pb-5 mt-5 tokenized_gold tokenized_gold_color ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 mt-5 mb-4">
              <div className="main_title">
                <h2 className="h2 text-primary">CGO Listing Library</h2>
                <h3 className="h3 text-secondary">Details require to list ComTech Gold (CGO) token with exchange</h3>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 mb-4 ">
              <div className="bg-primary-dark library border-radius p-lg-4 p-2 text-center tokenized_gold_box">
                <h3>Project Name</h3>
                <p>ComTech Gold</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4 ">
              <div className="bg-primary-dark library border-radius p-lg-4 p-2 text-center tokenized_gold_box">
                <h3>Token Name</h3>
                <p>ComTech Gold Token</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4 ">
              <div className="bg-primary-dark library border-radius p-lg-4 p-2 text-center tokenized_gold_box">
                <h3>Token Symbol</h3>
                <p>CGO</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4 ">
              <div className="bg-primary-dark library border-radius p-lg-4 p-2 text-center tokenized_gold_box">
                <h3>Total Supply</h3>
                <p>1 Billon</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4 ">
              <div className="bg-primary-dark library border-radius p-lg-4 p-2 text-center tokenized_gold_box">
                <h3>Decimals</h3>
                <p>18</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4 ">
              <div className="bg-primary-dark library border-radius p-lg-4 p-2 text-center tokenized_gold_box">
                <h3>Project website</h3>
                <p><Link to="https://comtechgold.com" target="_blank"> www.comtechgold.com </Link></p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mt-4">
              <p className="fs-22 ls-45 text-primary1">XRC-20 token built on <Link>XDC Network</Link></p>
              <p className="fs-22 ls-45 text-primary1">CGO Smart Contract Address: <Link to="https://explorer.xinfin.network/tokens/xdc8f9920283470f52128bf11b0c14e798be704fd15" target='_blank'>xdc8f9920283470f52128bf11b0c14e798be704fd15</Link></p>
              <p className="fs-22 ls-45 text-primary1">(Please cross check the CGO Address before any transfers are made. Tokens should never be sent to Smart Contract Address. Any tokens sent to smart contract cannot be retrieved.)</p>
              <p className="fs-22 ls-45 text-primary1">White Paper: <Link to="../assets/pdf/Routine_audit.pdf" download target='_blank'>Download Whitepaper</Link></p>
              <p className="fs-22 ls-45 text-primary1">Smart Contract Audit Report: <Link to="../assets/pdf/ComtechGold_New_Audit.pdf" download target='_blank'>Download</Link></p>
              <p className="fs-22 ls-45 text-primary1">Twitter: <Link to="https://twitter.com/ComTechOfficial" target="_blank">https://twitter.com/ComTechOfficial</Link></p>
              <p className="fs-22 ls-45 text-primary1">LinkedIn: <Link to="https://www.linkedin.com/company/comtechofficial" target="_blank">https://www.linkedin.com/company/comtechofficial/</Link></p>
              <p className="fs-22 ls-45 text-primary1">Facebook: <Link to="https://fb.me/ComTechGold" target="_blank">https://fb.me/ComTechGold</Link></p>
              <p className="fs-22 ls-45 text-primary1">Telegram: <Link to="https://t.me/ComTechGold" target="_blank">https://t.me/ComTechGold</Link></p>
              <p className="fs-22 ls-45 text-primary1">Medium: <Link to="https://medium.com/@comtech" target="_blank">https://medium.com/@comtech</Link></p>
              <p className="fs-22 ls-45 text-primary1">GitHub: <Link to="https://github.com/ComTechGold" target="_blank">https://github.com/ComTechGold</Link></p>
              <p className="fs-22 ls-45 text-primary1">Discord: <Link to="https://discord.gg/WbSjANXj7A" target="_blank">https://discord.gg/WbSjANXj7A</Link></p>
              <p className="fs-22 ls-45 text-primary1">Reddit: <Link to="https://www.reddit.com/r/ComTechGold" target="_blank">https://www.reddit.com/r/ComTechGold</Link></p>
              <p className="fs-22 ls-45 text-primary1">YouTube: <Link to="https://www.youtube.com/channel/UCh6TF0kcmIHkO-1jG7eKH_w" target="_blank">https://www.youtube.com/channel/UCh6TF0kcmIHkO-1jG7eKH_w</Link></p>
              <p className="fs-22 ls-45 text-primary1">ComTech Gold Logo: <Link to="../assets/img/cgo-logo.rar" download target="_blank">Download ComTech Gold Logo</Link></p>
            </div>
          </div>

        </div>  
      </section>
    </div>
  )
}

export default CGO