import React from 'react';
import { Link } from 'react-router-dom'
const Routine = () => {
  return (
    <div>
    
      <section className="pt-4 pb-4 tokenized_gold">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 mb-4">
              <div className="main_title">
                <h2 className="h2 text-primary">Routine Audits</h2>
                <h3 className="h3 text-secondary">Regular Audit reports from custodian.</h3>
              </div>
            </div>
            <div className="col-12">
              <div className="table-responsive mt-5 mb-5 bg-primary-dark gold_pattern">
                <table className="table table-dark">
                  <thead>
                    <tr>

                      <th scope="col">From</th>
                      <th scope="col">To</th>
                      <th scope="col">Opening Balance</th>
                      <th scope="col">Deposit</th>
                      <th scope="col">Withdrawals</th>
                      <th scope="col"> Closing Balance</th>
                      <th scope="col">Transguard Report</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="7" > </td>
                    </tr>
                    <tr>
                      <td className="text-center">March 08, 2023</td>
                      <td className="text-center">October 11, 2023</td>
                      <td className="text-center">141 kgs</td>
                      <td className="text-center">3 kgs</td>
                      <td className="text-center">0 kgs</td>
                      <td className="text-center">144 kgs</td>
                      <td className="text-center"><a href="../assets/pdf/Routine_audit.pdf" target="_blank" className="btn btn-outline-primary">Download</a></td>
                    </tr>
                    <tr>
                      <td colSpan="7" className="p-0"><img className="w-100" src="assets/images/footer_line.png" /> </td>
                    </tr>
                    <tr>
                      <td className="text-center">January 05, 2023</td>
                      <td className="text-center">March 08, 2023</td>
                      <td className="text-center">124 kgs</td>
                      <td className="text-center">17 kgs</td>
                      <td className="text-center">0 kgs</td>
                      <td className="text-center">141 kgs</td>
                      <td  className="text-center"><a href="../assets/pdf/Download1.pdf" target="_blank" className="btn btn-outline-primary">Download</a></td>
                    </tr>
                    <tr>
                      <td colSpan="7" className="p-0"><img className="w-100" src="assets/images/footer_line.png" /> </td>
                    </tr>
                    <tr>
                      <td className="text-center">Aug 31, 2022</td>
                      <td className="text-center">January 04, 2023</td>
                      <td className="text-center">122 kgs</td>
                      <td className="text-center">2 kgs</td>
                      <td className="text-center">0 kgs</td>
                      <td className="text-center">124 kgs</td>
                      <td  className="text-center">
                        
                        <Link to="../assets/pdf/Download2.pdf" target="_blank" className="btn btn-outline-primary">Download</Link></td>

                    </tr>
                    <tr>
                      <td colSpan="7" className="p-0"><img className="w-100" src="assets/images/footer_line.png" /> </td>
                    </tr>
                    <tr>
                      <td className="text-center">July 13, 2022</td>
                      <td className="text-center">Aug 30, 2022</td>
                      <td className="text-center">121 kgs</td>
                      <td className="text-center">1 kgs</td>
                      <td className="text-center">0 kgs</td>
                      <td className="text-center">122 kgs</td>
                      <td className="text-center"><Link to="../assets/pdf/Download3.pdf" target="_blank" className="btn btn-outline-primary">Download</Link></td>

                    </tr>
                    <tr>
                      <td colSpan="7" className="p-0"><img className="w-100" src="assets/images/footer_line.png" /> </td>
                    </tr>
                    <tr>
                      <td className="text-center">June 07, 2022</td>
                      <td className="text-center">July 12, 2022</td>
                      <td className="text-center">104 kgs</td>
                      <td className="text-center">17 kgs</td>
                      <td className="text-center">0 kgs</td>
                      <td className="text-center">121 kgs</td>
                      <td className="text-center"><Link to="../assets/pdf/Download4.pdf" target="_blank" className="btn btn-outline-primary">Download</Link></td>
                    </tr>
                    <tr>
                      <td colSpan="7" className="p-0"><img className="w-100" src="assets/images/footer_line.png" /> </td>
                    </tr>
                    <tr>
                      <td className="text-center">May 26, 2022</td>
                      <td className="text-center">June 06, 2022</td>
                      <td className="text-center">100 kgs</td>
                      <td className="text-center">4 kgs</td>
                      <td className="text-center">0 kgs</td>
                      <td className="text-center">104 kgs</td>
                      <td className="text-center"><Link to="../assets/pdf/Download5.pdf" target="_blank" className="btn btn-outline-primary">Download</Link></td>
                    </tr>
                    <tr>
                      <td colSpan="7" className="p-0"><img className="w-100" src="assets/images/footer_line.png" /> </td>
                    </tr>
                    <tr>
                      <td className="text-center">May 01, 2022</td>
                      <td className="text-center">May 25, 2022</td>
                      <td className="text-center">0 kgs</td>
                      <td className="text-center">100 kgs</td>
                      <td className="text-center">0 kgs</td>
                      <td className="text-center">100 kgs</td>
                      <td className="text-center"><a href="../assets/pdf/Download6.pdf" target="_blank" className="btn btn-outline-primary">Download</a></td>
                    </tr>
    
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default Routine