import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  // const scrollToTransparency = async () => {
  //   navigate('/Digitalgold');
  //   await new Promise((resolve) => setTimeout(resolve, 1000));
  //   const transparencySection = document.getElementById('Transparency');
  //   if (transparencySection) {
  //     transparencySection.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };

  // const scrollToFeestructure = async () => {
  //   navigate('/Digitalgold');
  //   await new Promise((resolve) => setTimeout(resolve, 1000));
  //   const transparencySection = document.getElementById('Feestructure');
  //   if (transparencySection) {
  //     transparencySection.scrollIntoView({ behavior: 'smooth' });

  //   }
  // };

  // const scrollToFAQs = async () => {
  //   navigate('/Digitalgold');
  //   await new Promise((resolve) => setTimeout(resolve, 1000));
  //   const transparencySection = document.getElementById('FAQs');
  //   if (transparencySection) {
  //     transparencySection.scrollIntoView({ behavior: 'smooth' });

  //   }
  // };



  const scrollToFeatures = async () => {
    // navigate('/Digitalgold');
    await new Promise((resolve) => setTimeout(resolve, 10));
    const transparencySection = document.getElementById('Features');

    if (transparencySection) {
      const goldHeading = transparencySection.querySelector('h2.text-primary');

      // if (goldHeading) {
      //   goldHeading.style.marginTop = '100px';
      // }

      transparencySection.scrollIntoView({ behavior: 'smooth' });
      window.scrollBy(0, -10000);
    }
  };
  const scrollToTransparency = async () => {
    // navigate('/Digitalgold');
    await new Promise((resolve) => setTimeout(resolve, 10));
    const transparencySection = document.getElementById('Transparency');
    if (transparencySection) {
      const goldHeading = transparencySection.querySelector('h2.text-primary');

      if (goldHeading) {
        goldHeading.style.marginTop = '50px';
      }
      transparencySection.scrollIntoView({ behavior: 'smooth' });
    }
  }
  const scrollToFeestructure = async () => {
    // navigate('/Digitalgold');
    await new Promise((resolve) => setTimeout(resolve, 10));
    const transparencySection = document.getElementById('Feestructure');
    if (transparencySection) {
      const goldHeading = transparencySection.querySelector('h2.text-primary');

      if (goldHeading) {
        goldHeading.style.marginTop = '50px';
      }
      transparencySection.scrollIntoView({ behavior: 'smooth' });
      // setIsMenuOpen(false);
    }
  };

  const scrollToFAQs = async () => {
    // navigate('/Digitalgold');
    await new Promise((resolve) => setTimeout(resolve, 10));
    const transparencySection = document.getElementById('FAQs');
    if (transparencySection) {
      const goldHeading = transparencySection.querySelector('h2.text-primary');

      if (goldHeading) {
        goldHeading.style.marginTop = '50px';
      }
      transparencySection.scrollIntoView({ behavior: 'smooth' });
      // setIsMenuOpen(false);
    }
  };

  return (
    <div>
      <footer className="">
        {/* <div className="container">
  <div className="row">
    <div className="col-12">
      <div className="header_bg footer_inner bg-primary-dark border-radius p-4">
        <div className="row z-index-3">
          <div className="col-lg-3 pt-4 pb-4">
            <div className="footer_logo mb-4">
              <img src="assets/images/logo.png" alt="" style={{width:157}} />
            </div>
            <div className="social_icon">
              <ul className='footericon'>
              <Link to="https://www.facebook.com/ComTechGold" target='_blank' style={{textDecoration:"none"}}>  <li><i className="fa-brands fa-facebook-f"></i></li></Link>
              <Link to="https://twitter.com/ComTechOfficial" target='_blank' style={{textDecoration:"none"}}>  <li><i className="fa-brands fa-twitter"></i></li></Link>
              <Link to="https://www.linkedin.com/company/comtechofficial" target='_blank' style={{textDecoration:"none"}}>  <li><i className="fa-brands fa-linkedin-in"></i></li></Link>
              <Link to="https://www.youtube.com/channel/UCh6TF0kcmIHkO-1jG7eKH_w" target='_blank' style={{textDecoration:"none"}}>  <li><i className="fa-brands fa-youtube"></i></li></Link>
               <Link to="https://t.me/ComTechGold" target='_blank' style={{textDecoration:"none"}}> <li><i className="fa-brands fa-telegram"></i></li></Link>
              </ul>
            </div>
          </div>
          <div className="col-lg-9"> 
            <div className="row"> 
            <div className="col-lg-3 col-sm-6 col-6 pt-4 pb-4 footer_links">
              <h6 className="text-white">COMPANY</h6>
              <ul>
                <li> <Link to='/Aboutus' className='links'>About Us</Link></li>
                <li><Link to='assets/ComTech_Gold_FAQ_Final.pdf' target='_blank' className='links'> FAQ </Link></li>
                <li><Link to ='/companyregistration' className='links'> Company Registration </Link> </li>
                <li><Link to='/Contactus' className='links'>Contact Us </Link> </li>
              </ul>
            </div>
            <div className="col-lg-3 col-sm-6 col-6 pt-4 pb-4 footer_links">
              <h6 className="text-white">DIGITAL GOLD</h6>
              <ul>
                <li> <Link to='/Digitalgold' className='links'>Features</Link></li>
                <li> <Link to='/Digitalgold' onClick={scrollToTransparency} className='links'>Transparency</Link></li>
                <li> <Link to='/Digitalgold' onClick={scrollToFeestructure} className='links'>Fee Structure</Link></li>
                <li> <Link to='/Digitalgold' onClick={scrollToFAQs} className='links'>FAQs</Link></li>
                
              </ul>
            </div>
            <div className="col-lg-3 col-sm-6 col-6 pt-4 pb-4 footer_links">
              <h6 className="text-white">TOKENIZED GOLD</h6>
              <ul>
              <li> <Link to='/TokenizedGold' className='links'>CGO Features</Link></li>
              <li> <Link to='/Cgolistinglibrary' className='links'>CGO Listing Libary</Link></li>
              <li> <Link to='/Feestructure' className='links'>Fee Structure</Link></li>
              <li> <Link to='/Deriskfromstablecoins' className='links'>De- Risk from Stablecoins</Link></li>
              <li> <Link to='/Transparency' className='links'>Transparency</Link></li>
              <li> <Link to='/Exchanges&Wallets' className='links'>Exchange & Wallets</Link></li>
              <li> <Link to='/faqs' className='links'>FAQs</Link></li>
              </ul>
            </div>
            <div className="col-lg-3 col-sm-6 col-6 pt-4 pb-4 footer_links">
              <h6 className="text-white">RESOURCES</h6>
              <ul>
                <li> <Link to='assets/Terms_and_Conditions.pdf'  target='_blank' className='links'>Terms and Conditions</Link></li>
                <li> <Link to='/privacypolicy' className='links'>Privacy Policy</Link></li>
              </ul>
            </div>
            </div>
          </div>
          <div className="col-12 mt-4">
            <img src="assets/images/footer_line.png" alt="footer line" />
          </div>
          <div className="col-12">
            <p className="copyright">© Copyright 2022 <Link to="/">ComTech FZCO</Link>. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> */}
        <section class="footer bg-secondary-light bg-vector px-80 pb-4">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="row z-index-3">
                  <div class="col-lg-3 pb-4">
                    <div class="footer_logo mb-4">
                    <img src="assets/images/logo-white.svg" style={{ width: 157 }} className='iconL' />
          <img src="assets/images/logo.svg" style={{ width: 157 }} className='iconD' />
                      {/* <img src="assets/images/logo.png" alt="" style={{ width: 157 }} /> */}
                    </div>
                    <div class="social_icon">
                      <ul class="footericon">
                        <a href="https://www.facebook.com/ComTechGold" target="_blank">
                          <li><i class="fa-brands fa-facebook-f"></i></li>
                        </a>
                        <a href="https://twitter.com/ComTechOfficial" target="_blank">
                          <li><i class="fa-brands fa-twitter"></i></li>
                        </a>
                        <a href="https://www.linkedin.com/company/comtechofficial" target="_blank">
                          <li><i class="fa-brands fa-linkedin-in"></i></li>
                        </a>
                        <a href="https://www.youtube.com/channel/UCh6TF0kcmIHkO-1jG7eKH_w" target="_blank">
                          <li><i class="fa-brands fa-youtube"></i></li>
                        </a>
                        <a href="https://t.me/ComTechGold" target="_blank">
                          <li><i class="fa-brands fa-telegram"></i></li>
                        </a>
                        <a href="https://www.instagram.com/comtechgold" target="_blank">
                          <li><i class="fa-brands fa-instagram"></i></li>
                        </a>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-9">
                    <div class="row">
                      <div class="col-lg-3 col-sm-6 col-6 pb-4 footer_links">
                        <h6 class="text-white">COMPANY</h6>
                        {/* <ul>
                                                <li><a class="links" href="/Aboutus">About Us</a></li>
                                                <li><a class="links" href="/assets/ComTech_Gold_FAQ_Final.pdf" target="_blank">FAQ</a></li>
                                                <li><a class="links" href="/companyregistration">Company Registration</a></li>
                                                <li><a class="links" href="/Contactus">Contact Us</a></li>
                                            </ul> */}
                        <ul>
                          <li> <Link to='/Aboutus' className='links'>About Us</Link></li>
                          <li><Link to='assets/pdf/ComTech_Gold_FAQ_Final.pdf' target='_blank' className='links'> FAQ </Link></li>
                          <li><Link to='/companyregistration' className='links'> Company Registration </Link> </li>
                          <li><Link to='/Contactus' className='links'>Contact Us </Link> </li>
                        </ul>
                      </div>
                      <div class="col-lg-3 col-sm-6 col-6 pb-4 footer_links">
                        <h6 class="text-white">DIGITAL GOLD</h6>
                        <ul >
                          {/* <li><a class="links" href="/Digitalgold">Features</a></li>
                                                <li><a class="links" onClick={scrollToTransparency} href="/Digitalgold">Transparency</a></li>
                                                <li><a class="links" onClick={scrollToFeestructure} href="/Digitalgold">Fee Structure</a></li>
                                                <li><a class="links"  onClick={scrollToFAQs} href="/Digitalgold">FAQs</a></li> */}
                          <li> <Link to='/Digitalgold' onClick={scrollToFeatures} className='links'>Features</Link></li>
                          <li> <Link to='/Digitalgold' onClick={scrollToTransparency} className='links'>Transparency</Link></li>
                          <li> <Link to='/Digitalgold' onClick={scrollToFeestructure} className='links'>Fee Structure</Link></li>
                          <li> <Link to='/Digitalgold' onClick={scrollToFAQs} className='links'>FAQs</Link></li>

                        </ul>
                      </div>
                      <div class="col-lg-3 col-sm-6 col-6 pb-4 footer_links">
                        <h6 class="text-white">TOKENIZED GOLD</h6>
                        {/* <ul style={{width:"max-content"}}>
                                                <li><a class="links" href="/TokenizedGold">CGO Features</a></li>
                                                <li><a class="links" href="/Cgolistinglibrary">CGO Listing Libary</a></li>
                                                <li><a class="links" href="/Feestructure">Fee Structure</a></li>
                                                <li><a class="links" href="/Deriskfromstablecoins">De- Risk from Stablecoins</a></li>
                                                <li><a class="links" href="/Transparency">Transparency</a></li>
                                                <li><a class="links" href="/Exchanges&amp;Wallets">Exchange &amp; Wallets</a></li>
                                                <li><a class="links" href="/faqs">FAQs</a></li>
                                            </ul> */}
                        <ul style={{ width: "max-content" }}>
                          <li><Link to="/TokenizedGold" className="links">Features</Link></li>
                          <li><Link to="/Cgolistinglibrary" className="links">CGO Listing Library</Link></li>
                          <li><Link to="/Feestructure" className="links">Fee Structure</Link></li>
                          <li><Link to="/Deriskfromstablecoins" className="links">De-Risk from Stablecoins</Link></li>
                          <li><Link to="/Transparency" className="links">Transparency</Link></li>
                          <li><Link to="/Exchanges&Wallets" className="links">Exchanges & Wallets</Link></li>
                          <li><Link to="/faqs" className="links">FAQs</Link></li>
                        </ul>

                      </div>
                      <div class="col-lg-3 col-sm-6 col-6 pb-4 footer_links">
                        <h6 class="text-white">RESOURCES</h6>

                        <ul className='abc'>
                          <li> <Link to='assets/pdf/Terms_and_Conditions.pdf' target='_blank' className='links'>Terms and Conditions</Link></li>
                          <li> <Link to='/privacypolicy' className='links'>Privacy Policy</Link></li>
                          {/* <li className='week'>


                            <div>
                              <h6 className="offer"><Link to='assets/pdf/Ramadan_Month_Offer_T&C.pdf' target='_blank'>Ramadan Month Offer</Link> </h6>
                              <img src="assets/images/moon.gif" className='valantine' alt="" />
                            </div>
                          </li> */}


                        </ul>


                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row z-index-3 footer-bottom align-items-center">
              <div class="col-12">
                <p class="copyright">© Copyright 2024 <a href="/">ComTech FZCO</a>. All rights reserved.</p>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </div>
  )
}

export default Footer